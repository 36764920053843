import React, { useCallback, useState } from "react";
import Markdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {
  Box,
  Flex,
  Avatar,
  Text,
  Em,
  Link,
  Button,
  Spinner,
  TextField,
  IconButton,
  Card,
} from "@radix-ui/themes";
import {
  ImageIcon,
  FileIcon,
  PersonIcon,
  ArrowUpIcon,
} from "@radix-ui/react-icons";
import {
  Message,
  AgentJoinedMessage,
  CloseChatMessage,
  ConnectingToAgentMessage,
  StandardMessage,
  FileUploadedMessage,
  ErrorMessage,
  RequestEmailMessage,
} from "../entities/Message";
import { MessageAvatarSVG } from "./loadSVG";

const components: Components = {
  a: ({ node, ...props }) => (
    <a {...props} target="_blank" rel="noopener noreferrer" />
  ),
};

function MessageText({ text }: { text: string }) {
  return (
    <div className="markdown-body">
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {text}
      </Markdown>
    </div>
  );
}

function getAuthorInitials(author?: string) {
  if (!author) {
    return;
  }

  const words = author.split(" ").map((x) => x[0]);

  if (words.length === 1) {
    return words[0];
  }

  return [words[0], words[words.length - 1]].join("");
}

function UserMessageComponent({
  deduplicationKey,
  text,
  data: { agentDisplayName },
}: StandardMessage) {
  const initials = getAuthorInitials(agentDisplayName) ?? "U";

  return (
    <Flex gap={"3"} align={"start"}>
      <MessageAvatarSVG initials={initials} kind={"user"} />

      <Box
        id={deduplicationKey}
        style={{
          color: "var(--userText)",
          padding: "2px 8px",
          borderRadius: "8px",
        }}
      >
        <MessageText text={text} />
      </Box>
    </Flex>
  );
}

function AgentMessageComponent({
  deduplicationKey,
  text,
  data: { agentDisplayName },
  customSVG,
}: StandardMessage & { customSVG: string }) {
  const initials = getAuthorInitials(agentDisplayName);

  return (
    <Flex gap={"3"} align={"start"}>
      <MessageAvatarSVG kind={"agent"} initials={initials} />

      <Box
        id={deduplicationKey}
        style={{
          color: "var(--agentText)",
          background: "var(--agentBackground)",
          padding: "8px 12px",
          borderRadius: "8px",
        }}
      >
        <MessageText text={text} />
      </Box>
    </Flex>
  );
}

function AutoAgentMessageComponent({
  deduplicationKey,
  text,
  customSVG,
}: StandardMessage & { customSVG: string }) {
  return (
    <Flex gap={"3"} align={"start"}>
      <MessageAvatarSVG path={customSVG} />

      <Box
        id={deduplicationKey}
        style={{
          background:
            "linear-gradient(260.74deg, rgba(249, 243, 227, 0.35) 4.89%, rgba(226, 234, 255, 0.35) 101.01%)",
          padding: "8px 12px",
          borderRadius: "8px",
        }}
      >
        <MessageText text={text} />
      </Box>
    </Flex>
  );
}

function ConnectingToAgentMessageComponent({ text }: ConnectingToAgentMessage) {
  return (
    <Text
      size={"2"}
      color={"gray"}
      style={{
        padding: "2px 8px",
      }}
    >
      <Em>{text}</Em>
    </Text>
  );
}

function AgentJoinedMessageComponent({
  text,
  data: { agentDisplayName },
}: AgentJoinedMessage) {
  return (
    <Text
      size={"2"}
      color={"gray"}
      style={{
        padding: "2px 8px",
      }}
    >
      <Em>
        {agentDisplayName != null
          ? `${agentDisplayName} has joined the chat`
          : text}
      </Em>
    </Text>
  );
}

function CloseChatMessageComponent({ text }: CloseChatMessage) {
  return (
    <Text
      size={"2"}
      color={"gray"}
      style={{
        padding: "2px 8px",
      }}
    >
      <Em>{text}</Em>
    </Text>
  );
}

function FileUploadedMessageComponent({
  data: { url, filename },
}: FileUploadedMessage) {
  const isImg = url.match(/\.(jpeg|jpg|gif|png)$/) != null;

  return (
    <Flex pl={"44px"}>
      <Link href={url} target="_blank" rel="noreferrer">
        <Flex
          gap={"10px"}
          align={"center"}
          style={{
            padding: "4px 8px 4px 4px",
            borderRadius: "8px",
            background: "var(--gray-a3)",
          }}
        >
          {isImg ? (
            <Avatar
              size={"2"}
              color={"gray"}
              src={url}
              fallback={<ImageIcon width={"24"} height={"24"} />}
            />
          ) : (
            <Avatar
              size={"2"}
              color={"indigo"}
              variant={"solid"}
              src={url}
              fallback={<FileIcon width={"24"} height={"24"} />}
            />
          )}

          <Text
            size={"3"}
            color={"gray"}
            highContrast
            truncate
            style={{ maxWidth: "120px" }}
          >
            {filename}
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
}

function ErrorMessageComponent({
  text,
  data: { errorType },
  onEscalate,
}: ErrorMessage & { onEscalate: () => Promise<void> }) {
  const [isEscalating, setIsEscalating] = React.useState(false);

  const handleEsclate = useCallback(async () => {
    setIsEscalating(true);
    await onEscalate();

    setIsEscalating(false);
  }, [onEscalate]);

  return (
    <Flex
      px={"12px"}
      py={"8px"}
      direction={"column"}
      gap={"5"}
      style={{ backgroundColor: "var(--gray-2)", borderRadius: "8px" }}
    >
      <Text size={"2"} color={"gray"}>
        <Em>{text}</Em>
      </Text>

      {errorType === "ai_failed" && (
        <Flex gap={"2"}>
          <Button
            size={"1"}
            color={"gray"}
            variant={"outline"}
            onClick={handleEsclate}
            disabled={isEscalating}
          >
            <Spinner loading={isEscalating}>
              <PersonIcon />
            </Spinner>
            Contact an Agent
          </Button>

          {/* <Button
            size={"1"}
            color={"gray"}
            variant={"solid"}
            highContrast
            onClick={console.log}
          >
            <ReloadIcon />
            Regenrate Response
          </Button> */}
        </Flex>
      )}
    </Flex>
  );
}

function RequestEmailMessageComponent({
  text,
  customSVG,
  data: { defaultEmail = "" },
  isLastMessage,
  onEscalationEmail,
}: RequestEmailMessage & {
  customSVG: string;
  isLastMessage: boolean;
  onEscalationEmail: (email: string) => Promise<void>;
}) {
  const [email, setEmail] = useState(defaultEmail);
  const [loading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(isLastMessage);

  const handleSubmitEmail = useCallback(async () => {
    setLoading(true);
    await onEscalationEmail(email);

    setShowInput(false);
    setLoading(false);
  }, [email, onEscalationEmail]);

  return (
    <Flex gap={"3"} align={"start"}>
      <MessageAvatarSVG path={customSVG} />

      <Flex
        flexGrow={"1"}
        direction={"column"}
        gap={"5"}
        style={{
          background:
            "linear-gradient(260.74deg, rgba(249, 243, 227, 0.35) 4.89%, rgba(226, 234, 255, 0.35) 101.01%)",
          padding: "8px 12px",
          borderRadius: "8px",
        }}
      >
        <MessageText text={text} />

        {showInput && (
          <Card
            variant="classic"
            size={"3"}
            className={"qc-Chat__Requset_Email__Input"}
          >
            <TextField.Root
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter your email"
            />

            <IconButton
              color="gray"
              variant="solid"
              radius="full"
              highContrast
              onClick={handleSubmitEmail}
              disabled={loading}
              loading={loading}
            >
              <ArrowUpIcon width="15" height="15" />
            </IconButton>
          </Card>
        )}
      </Flex>
    </Flex>
  );
}

type MessageComponentProps = Message & {
  customSVG: string;
  isLastMessage: boolean;
  onEscalate: () => Promise<void>;
  onEscalationEmail: (email: string) => Promise<void>;
};

export function MessageComponent(props: MessageComponentProps) {
  if (props.action === "standard_message" && props.author === "USER") {
    return <UserMessageComponent {...props} />;
  }

  if (props.action === "standard_message" && props.author === "LIVE_AGENT") {
    return <AgentMessageComponent {...props} />;
  }

  if (props.action === "standard_message" && props.author !== "LIVE_AGENT") {
    return <AutoAgentMessageComponent {...props} />;
  }

  if (props.action === "connecting_to_agent") {
    return <ConnectingToAgentMessageComponent {...props} />;
  }

  if (props.action === "agent_joined") {
    return <AgentJoinedMessageComponent {...props} />;
  }

  if (props.action === "close_chat") {
    return <CloseChatMessageComponent {...props} />;
  }

  if (props.action === "file_uploaded") {
    return <FileUploadedMessageComponent {...props} />;
  }

  if (props.action === "error") {
    return <ErrorMessageComponent {...props} />;
  }

  if (props.action === "request_email") {
    return <RequestEmailMessageComponent {...props} />;
  }

  return <></>;
}
