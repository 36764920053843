import { API_URL } from "../constants";

export const getChatConversation = async ({
  tenantId,
  chatSessionId,
}: {
  tenantId: string;
  chatSessionId: string;
}) => {
  try {
    const response = await fetch(
      `${API_URL}sessions-api/chat-session/tenants/${tenantId}/sessions/${chatSessionId}`,
      {
        method: "GET",
        headers: {
          "x-api-key": "KnASFJdASLKFJGM24k1cccAsdJ0BnhDtps",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log({ err });
  }
};
