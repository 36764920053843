import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import styled from "styled-components";
import { IoSparkles } from "react-icons/io5";
import { ChevronDown } from "@geist-ui/icons";
import { Message } from "ai/react";

import { EToolCallName } from "../api/useChat";

import { Input } from "./input";
import { EMessagesContainerType, TypeContext } from "./typeContext";
import { MessageComponent } from "./message";
import { fadeAndMoveUp } from "./utils";

function cleanEmail(email: string) {
  return email
    .replace(/[^\w@.]+$/, "")
    .trim()
    .replace(/[^\w@]+$/, "");
}

const getEmailIndex = (
  str: string
): { index: number; email: string | null } => {
  const chunks = str.split(" ");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const index = chunks.findIndex((chunk) => emailRegex.test(chunk));

  return {
    index: index,
    email: index === -1 ? null : cleanEmail(chunks[index]),
  };
};

const PoweredBy = () => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        padding: "0px 0px 10px",
        opacity: 0.5,
        fontSize: "12px",
        lineHeight: "20px",
        alignItems: "center",
        justifyContent: "center",
        gap: "2px",
      }}
    >
      Powered by{" "}
      <a
        style={{ display: "flex" }}
        href="https://www.quack.ai"
        target="_blank"
      >
        <img
          style={{ width: "68px" }}
          src="https://quack-assets.s3.amazonaws.com/company/logo-with-text.png"
          alt=""
        />
      </a>
    </div>
  );
};

export const MessagesContainer = ({
  title,
  onMessage,
  closeWidget,
  messages,
  question,
  setQuestion,
  handleSubmit,
  aiLoading,
  setMessages,
  isTyping,
  appendAndSave,
  handleEscalate,
  fetchEmail,

  showProactive,
  setShowProactive,
  escalation,
  // setEscalation,
  poweredByLine,
  fontSize,
  updateCSAT,
  trackProactive,
}: {
  title: string;
  onMessage: () => Promise<void>;
  closeWidget: () => void;
  messages: Message[];
  question: string;
  setQuestion: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  aiLoading: boolean;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isTyping: boolean;
  handleEscalate: (email: string) => void;
  fetchEmail: () => string;
  showProactive: boolean;
  setShowProactive: (val: boolean) => void;
  escalation: boolean;
  // setEscalation: (b: boolean) => void;
  poweredByLine?: boolean;
  fontSize: number;
  updateCSAT: (value: number) => Promise<void>;
  appendAndSave: (message: Message) => void;
  trackProactive: () => void;
}) => {
  const { type } = useContext(TypeContext);
  const [isLoading, setLoading] = useState(false);
  const isProactiveEventTracked = useRef<boolean>(false);

  const [isInputPresented, setInputPresented] = useState(true);
  const [messagesCountBeforeLoad, setMessagesBeforeLoad] = useState(0);
  // const escalationRef = useRef<boolean>(false);

  const [proactiveMessage, setProactiveMessage] = useState("");

  // useEffect(() => {
  //   if (escalation && email) {
  //     handleEscalate(email);
  //   }
  // }, [escalation, email]);

  const email = useMemo(() => {
    return fetchEmail();
  }, [JSON.stringify(messages)]);

  useEffect(() => {
    if (!aiLoading && isLoading) {
      setLoading(false);
    }
  }, [aiLoading, isLoading]);

  useEffect(() => {
    if (type === EMessagesContainerType.PROACTIVE) {
      setInputPresented(false);
      setProactiveMessage("Hi, is there anything I can help you with?");
      setShowProactive(true);
      setInputPresented(true);
      if (!isProactiveEventTracked.current) {
        trackProactive();
        isProactiveEventTracked.current = true;
      }
    } else if (showProactive) {
      setShowProactive(false);
    }
  }, [type, showProactive]);

  const submitQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    if (showProactive) {
      setMessagesBeforeLoad(messages.length + 2);
    } else {
      setMessagesBeforeLoad(messages.length + 1);
    }
    await onMessage();
    setLoading(true);
    handleSubmit(e);
  };

  return (
    <MessagesContainerWrapper type={type} className={type}>
      {showProactive && (
        <div className="proactiveContainer">
          <button onClick={closeWidget}>
            <ChevronDown />
          </button>
          <MessageComponent
            message={{
              role: "assistant",
              content: proactiveMessage,
              id: "loading",
            }}
            icon={<IoSparkles />}
            fontSize={fontSize}
            updateCSAT={updateCSAT}
          />
        </div>
      )}
      {type === EMessagesContainerType.CHAT && (
        <div className="top">
          <div>
            <IoSparkles />
            {title}
          </div>
          <div className="button" onClick={closeWidget}>
            <ChevronDown />
          </div>
        </div>
      )}

      <div className="messages">
        {messages.map((message, index) => (
          <MessageComponent
            key={message.id}
            message={message}
            isLastMessage={index === messages.length - 1}
            fontSize={fontSize}
            updateCSAT={updateCSAT}
          />
        ))}
        {aiLoading && messagesCountBeforeLoad === messages.length && (
          <MessageComponent
            message={{
              role: "assistant",
              content: "Loading...",
              id: "loading",
            }}
            isLoading={aiLoading}
            isLastMessage={true}
            fontSize={fontSize}
            updateCSAT={updateCSAT}
          />
        )}
      </div>

      {isInputPresented && (
        <div className="bottom">
          <Input
            value={question}
            handleInputChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setQuestion(e);
            }}
            messagesLength={messages.length}
            handleSubmit={
              !escalation
                ? submitQuestion
                : (e) => {
                    const textarea = (e.target as HTMLTextAreaElement)
                      .children[0];

                    const input = (textarea as HTMLTextAreaElement).value;
                    const { index, email: inputEmail } = getEmailIndex(input);
                    if (index !== -1 && inputEmail) {
                      (e.target as HTMLFormElement).style.border =
                        "1px solid rgb(199, 199, 199)";

                      (textarea as HTMLTextAreaElement).value = "";

                      appendAndSave({
                        content: inputEmail,
                        role: "user",
                        id: "email",
                      });

                      setTimeout(() => {
                        handleEscalate(inputEmail);
                      }, 300);
                    } else {
                      (e.target as HTMLFormElement).style.border =
                        "1px solid red";
                    }
                  }
            }
            emailRequired={escalation && !email}
            isTyping={isTyping}
          />
        </div>
      )}
      {poweredByLine && <PoweredBy />}
    </MessagesContainerWrapper>
  );
};

const MessagesContainerWrapper = styled.div<{ type: EMessagesContainerType }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  border: ${(props) =>
    props.type !== EMessagesContainerType.CHAT
      ? ""
      : `1px solid ${props.theme.light.borderLight}`};
  max-height: 664px;
  animation: ${fadeAndMoveUp} 0.2s ease-in-out;

  @media (max-width: 768px) {
    &.chat {
      overflow: hidden !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: unset !important;
      height: 100% !important;
      max-width: unset !important;
      position: fixed !important;
    }
  }

  &.chat {
    background-color: ${(props) => props.theme.light.bgPrimaryQuack};
    justify-content: space-between;
    border-radius: 16px;
    height: 560px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid ${(props) => props.theme.light.borderLight};

      div {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${(props) => props.theme.light.textPrimary};
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        transition: all 0.2s;

        &.button {
          padding: 4px;
          cursor: pointer;
          border-radius: 6px;

          svg {
            width: 18px;
            height: 18px;
          }

          &:hover {
            background: ${(props) => props.theme.light.bgCard};
          }
        }
      }
    }

    .messages {
      display: flex;
      flex: 1;
      overflow-y: scroll;
      padding: 16px;
      padding-bottom: 40px;
      box-sizing: border-box;
      flex-direction: column;
      align-items: flex-start;
      scroll-behavior: smooth;
      gap: 6px;

      ::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }

      ::-webkit-scrollbar-track {
        background: transparent !important;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        border: 3px solid transparent !important;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #5e5e5e !important;
      }

      > .hasAgentName {
        .agentHasJoined {
          display: block;
          font-size: 12px;
          text-align: center;
          color: #60646c;
        }
      }

      > .hasAgentName ~ .hasAgentName {
        border: none;
        .agentHasJoined {
          display: none;
        }
      }
    }
  }

  .bottom {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .proactiveContainer {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 0 12px;
    animation: ${fadeAndMoveUp} 0.2s ease-in-out;

    button {
      margin-right: 12px;
      all: unset;
      cursor: pointer;
      padding: 4px;
      display: flex;
      align-self: flex-end;
      border-radius: 32px;
      background: ${(props) => props.theme.light.bgCard};
      color: ${(props) => props.theme.light.iconPrimary};
      justify-content: center;
      align-items: center;
      transition: all 0.2s;

      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background: ${(props) => props.theme.light.bgPrimaryQuack};
      }
    }
  }
`;
