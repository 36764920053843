import { useMutation } from "react-query";
import { useApi } from "./useApi";
import { AxiosResponse } from "axios";

interface EscalateParams {
  chatSessionId: string;
}

export function useEscalate() {
  const { api } = useApi();

  return useMutation(async (chatSessionId: string) => {
    return api.post<void, AxiosResponse<void>, EscalateParams>(
      "/sessions-api/quackchat/escalate",
      {
        chatSessionId,
      }
    );
  });
}
