import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { QuackChatConfig } from "./types";
import { ChatComponent } from "./chat-comp";
import { QuackChatWidget } from "./v2/QuackChatWidget";

export type ChatProps = QuackChatConfig & {
  sessionId: string;
  token: string;
  email: string;
  track: (eventName: string, properties: any) => void;
};

export function renderChat(props: ChatProps) {
  const quackContainer = document.getElementById("quack-container");

  var link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  link.setAttribute(
    "href",
    "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
  );
  document.head.appendChild(link);

  const version = props.version ?? "v1";

  try {
    const root = createRoot(quackContainer!);
    root.render(
      <React.StrictMode>
        {version === "v1" && (
          <ChatComponent {...props} showChatTime={new Date().toISOString()} />
        )}

        {version === "v2" && (
          <QuackChatWidget
            token={props.token}
            tenant={props.tenantId}
            settings={props.chatSettings}
            track={props.track}
          />
        )}
      </React.StrictMode>
    );
  } catch (error) {
    console.error("Error rendering ChatComponent:", error);
  }
}
