import { useQuery } from "react-query";
import { useApi } from "./useApi";
import { useSentryScope } from "../hooks/useSentryScope";

export interface ChatSessionResponse {
  chatSessionId: string;
  websocketEndpoint: string;
}

function isChatSessionResponse(data: unknown): data is ChatSessionResponse {
  return (
    typeof data === "object" &&
    data !== null &&
    "chatSessionId" in data &&
    "websocketEndpoint" in data
  );
}

const CHAT_SESSION_LOCAL_STORAGE_KEY = "quackchat/chat_session";

function parseChatSessionResponseFromLocalStorage(
  tenant: string
): ChatSessionResponse | null {
  const storedData = localStorage.getItem(
    `${CHAT_SESSION_LOCAL_STORAGE_KEY}/${tenant}`
  );
  if (!storedData) {
    return null;
  }

  try {
    const parsedData: unknown = JSON.parse(storedData);
    if (isChatSessionResponse(parsedData)) {
      return parsedData;
    }
  } catch (error) {}

  return null;
}

export function clearChatSessionResponseFromLocalStorage(tenant: string) {
  localStorage.removeItem(`${CHAT_SESSION_LOCAL_STORAGE_KEY}/${tenant}`);
}

export function useChatSession(tenant: string, enabled: boolean) {
  const scope = useSentryScope();
  const { api } = useApi();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["chat-session", tenant],
    queryFn: async () => {
      // Check if data exists in localStorage
      const storedChatSession =
        parseChatSessionResponseFromLocalStorage(tenant);
      if (storedChatSession != null) {
        scope.setUser({
          id: tenant,
          chatSessionId: storedChatSession.chatSessionId,
          resotred: true,
        });
        scope.captureMessage("Chat session restored", "info");

        return storedChatSession;
      }

      // If not, send request
      const response = await api.post<ChatSessionResponse>(
        "/sessions-api/quackchat/chat-session",
        {
          tenant: tenant,
        }
      );

      scope.setUser({
        id: tenant,
        chatSessionId: response.data.chatSessionId,
        resotred: false,
      });
      scope.captureMessage("Chat session created", "info");

      // Save response in localStorage
      if (response.data) {
        localStorage.setItem(
          `${CHAT_SESSION_LOCAL_STORAGE_KEY}/${tenant}`,
          JSON.stringify(response.data)
        );
      }

      return response.data;
    },
    enabled,
  });

  return { data, isLoading, stateNewChateSession: refetch };
}
