import React from "react";
import { Text, Flex } from "@radix-ui/themes";
import { QuackLogo } from "../assets/QuackLogo";

export function Footer() {
  return (
    <Flex py={"1"} px={"3"} justify={"center"} align={"center"} gap={"1"}>
      <Text color={"gray"} style={{ fontSize: "0.5625rem" }}>
        Powered by
      </Text>

      <QuackLogo />

      <Text color={"gray"} style={{ fontSize: "0.5625rem" }}>
        Quack AI
      </Text>
    </Flex>
  );
}
