import React, {
  useState,
  useContext,
  createContext,
  PropsWithChildren,
  useEffect,
} from "react";
import {
  BrowserClient,
  defaultStackParser,
  getDefaultIntegrations,
  makeFetchTransport,
  Scope,
} from "@sentry/browser";
import { ENV } from "../../constants";

// Create a context for the Sentry Scope
const SentryScopeContext = createContext<Scope>(null!);

export function SentryScopeProvider({ children }: PropsWithChildren) {
  const [scope, setScope] = useState<Scope>(null!);

  useEffect(() => {
    const integrations = getDefaultIntegrations({}).filter(
      (defaultIntegration) => {
        return !["BrowserApiErrors", "Breadcrumbs", "GlobalHandlers"].includes(
          defaultIntegration.name
        );
      }
    );

    const client = new BrowserClient({
      dsn: "https://4714c0ae60fccd57b1b6b86dc0d5cee3@o4506401607909376.ingest.us.sentry.io/4508953474039808",
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: integrations,
      environment: ENV,
    });

    const scope = new Scope();
    scope.setClient(client);
    client.init(); // initializing has to be done after setting the client on the scope
    // You can capture exceptions manually for this client like this:
    // scope.captureException(new Error("example"));

    setScope(scope);
  }, []);

  return (
    <SentryScopeContext.Provider value={scope}>
      {scope != null && children}
    </SentryScopeContext.Provider>
  );
}

export function useSentryScope(): Scope {
  const scope = useContext(SentryScopeContext);

  if (scope == null) {
    throw new Error("useSentryScope must be used within a SentryScopeProvider");
  }

  return scope;
}
