import { useQuery } from "react-query";
import { BaseMessage } from "../core/entities/Message";
import { useApi } from "./useApi";

interface GetChatMessagesResponse {
  chatMessages: (BaseMessage & { data: { agentDisplayName?: string } })[];
  tenant: string;
  chatSessionId: string;
}

export function useGetChatMessages(tenantId: string, chatSessionId?: string) {
  const { api } = useApi();

  return useQuery({
    queryKey: ["chatMessages", tenantId, chatSessionId],
    queryFn: async () => {
      if (chatSessionId == null) {
        return;
      }

      const response = await api.get<GetChatMessagesResponse>(
        `/sessions-api/quackchat/tenants/${tenantId}/chat-sessions/${chatSessionId}/messages`
      );

      return response.data;
    },
    enabled: chatSessionId != null,
  });
}
