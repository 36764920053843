import { useQuery } from "react-query";
import { useApi } from "./useApi";
import { AxiosResponse } from "axios";

export function useHealth(enabled: boolean) {
  const { api } = useApi();

  return useQuery({
    queryKey: "health",
    queryFn: async () => {
      const { data } = await api.get<"OK", AxiosResponse<"OK">>(
        "/sessions-api/health"
      );

      return data;
    },
    enabled,
    refetchInterval: 30_000,
  });
}
