import { z } from "zod";

const messageTypes = [
  "standard_message",
  "request_email",
  "error",
  "close_chat",
  "connecting_to_agent",
  "agent_joined",
  "file_uploaded",
] as const;
type MessageType = (typeof messageTypes)[number];

const authorTypes = ["AUTO_AGENT", "USER", "LIVE_AGENT", "SYSTEM"] as const;
type AuthorType = (typeof authorTypes)[number];

export interface BaseMessage {
  action: MessageType;
  author: AuthorType;
  deduplicationKey: string;
  text: string;
  createdAt: string;
}

export interface StandardMessage extends BaseMessage {
  action: "standard_message";
  data: {
    agentDisplayName?: string;
  };
}

export interface RequestEmailMessage extends BaseMessage {
  action: "request_email";
  author: "SYSTEM";
  text: string;
  data: {
    defaultEmail?: string;
  };
}

const errorTypes = ["ai_failed", "generic_error"] as const;
export type ErrorType = (typeof errorTypes)[number];

export interface ErrorMessage extends BaseMessage {
  action: "error";
  author: "SYSTEM";
  data: {
    errorType: ErrorType;
  };
}

export interface CloseChatMessage extends BaseMessage {
  action: "close_chat";
  author: "SYSTEM";
  data: {
    showCSAT?: boolean;
  };
}

export interface ConnectingToAgentMessage extends BaseMessage {
  action: "connecting_to_agent";
  author: "SYSTEM";
  data: {};
}

export interface AgentJoinedMessage extends BaseMessage {
  action: "agent_joined";
  author: "SYSTEM";
  data: {
    agentDisplayName?: string;
  };
}

export interface FileUploadedMessage extends BaseMessage {
  action: "file_uploaded";
  author: "SYSTEM";
  data: {
    url: string;
    filename: string;
  };
}

export type Message =
  | StandardMessage
  | RequestEmailMessage
  | ErrorMessage
  | CloseChatMessage
  | ConnectingToAgentMessage
  | AgentJoinedMessage
  | FileUploadedMessage;

const baseMessageSchema = {
  action: z.enum(messageTypes),
  author: z.enum(authorTypes),
  deduplicationKey: z.string(),
  text: z.string(),
  createdAt: z.string(),
};

const standardMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("standard_message"),
  data: z.object({
    agentDisplayName: z.string().optional(),
  }),
});

const requestEmailMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("request_email"),
  author: z.literal("SYSTEM"),
  data: z.object({
    defaultEmail: z.string().optional(),
  }),
});

const errorMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("error"),
  author: z.literal("SYSTEM"),
  data: z.object({
    errorType: z.enum(errorTypes),
  }),
});

const closeChatMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("close_chat"),
  author: z.literal("SYSTEM"),
  data: z.object({
    showCSAT: z.boolean().optional(),
  }),
});

const connectingToAgentMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("connecting_to_agent"),
  author: z.literal("SYSTEM"),
  data: z.object({}),
});

const agentJoinedMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("agent_joined"),
  author: z.literal("SYSTEM"),
  data: z.object({
    agentDisplayName: z.string().optional(),
  }),
});

const fileUploadedMessageSchema = z.object({
  ...baseMessageSchema,
  action: z.literal("file_uploaded"),
  author: z.literal("SYSTEM"),
  data: z.object({
    url: z.string(),
    filename: z.string(),
  }),
});

export const messageSchema = z.discriminatedUnion("action", [
  standardMessageSchema,
  requestEmailMessageSchema,
  errorMessageSchema,
  closeChatMessageSchema,
  connectingToAgentMessageSchema,
  agentJoinedMessageSchema,
  fileUploadedMessageSchema,
]);
