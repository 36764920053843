import { useEffect, useRef, useState } from "react";
import { Message } from "ai/react";

import { EMessagesContainerType } from "../chat-comp/typeContext";

export const useHandleUrlChange = ({
  whiteListUrls,
  proactiveTime,
  type,
  messages,
  showInput,
  proactiveListUrls,
  setType,
  setShowInput,
  showChatTime,
  disappearAfterClose,
}: {
  whiteListUrls: string[];
  type: EMessagesContainerType;
  messages: Message[];
  showInput: boolean;
  proactiveListUrls: string[];
  setType: (type: EMessagesContainerType) => void;
  setShowInput: (showInput: boolean) => void;
  showChatTime: string;
  proactiveTime: number;
  disappearAfterClose?: number;
}) => {
  const [showChat, setShowChat] = useState(true);
  const [proactived, setProactived] = useState(false);

  const proactiveTimeout = useRef<NodeJS.Timeout | null>(null);
  const messagesCount = messages?.length;

  useEffect(() => {
    if (messages.length !== 0) {
      setProactived(true);
      if (proactiveTimeout.current) {
        clearTimeout(proactiveTimeout.current);
      }
    }
  }, [messages]);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data === "quack-disable-proactive") {
        setProactived(true);
        if (proactiveTimeout.current) {
          clearTimeout(proactiveTimeout.current);
        }
      } else if (event.data === "quack-enable-proactive") {
        if (proactiveTimeout.current) {
          setProactived(false);
          clearTimeout(proactiveTimeout.current);
        }
      }
    });
  }, []);

  useEffect(() => {
    const handleProactiveTimeout = () => {
      if (proactiveTimeout.current) {
        clearTimeout(proactiveTimeout.current);
      }

      if (
        !showInput &&
        type === EMessagesContainerType.REST &&
        !proactived &&
        proactiveListUrls?.some((url) => window.location.href.includes(url)) &&
        messages?.length === 0
      ) {
        if (proactiveTime) {
          proactiveTimeout.current = setTimeout(() => {
            setType(EMessagesContainerType.PROACTIVE);
            setProactived(true);
            setShowInput(true);
          }, proactiveTime * 1000);
        }
      }
    };

    const isWhitelisted = whiteListUrls.some((url) =>
      window.location.href.includes(url)
    );
    setShowChat(isWhitelisted);
    handleProactiveTimeout();

    const handleChatVisibility = () => {
      const isWhitelisted = whiteListUrls.some((url) =>
        window.location.href.includes(url)
      );
      const chatStarted =
        type === EMessagesContainerType.CHAT || messages.length > 2;
      setShowInput(false);
      setShowChat(isWhitelisted && chatStarted);
    };

    (function (history) {
      const pushState = history.pushState;
      const replaceState = history.replaceState;

      history.pushState = function (state, title, url) {
        const result = pushState.apply(history, arguments);
        handleChatVisibility();
        handleProactiveTimeout();
        return result;
      };

      history.replaceState = function (state, title, url) {
        const result = replaceState.apply(history, arguments);
        handleChatVisibility();
        handleProactiveTimeout();
        return result;
      };

      window.addEventListener("popstate", handleChatVisibility);
    })(window.history);

    return () => {
      window.removeEventListener("popstate", handleChatVisibility);
      if (proactiveTimeout.current) {
        clearTimeout(proactiveTimeout.current);
      }
    };
  }, [
    showInput,
    type,
    proactived,
    proactiveListUrls,
    whiteListUrls,
    messages,
    messagesCount,
    showChatTime,
  ]);

  const [wasOpened, setWasOpened] = useState(false);

  useEffect(() => {
    if (disappearAfterClose) {
      let timeoutId: NodeJS.Timeout;

      if (!wasOpened && showInput) {
        setWasOpened(true);
      } else if (wasOpened && !showInput) {
        timeoutId = setTimeout(() => {
          setShowChat(false);
        }, disappearAfterClose * 1000);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
    return () => {};
  }, [showInput, wasOpened, disappearAfterClose]);

  return { showChat };
};
