import React, { CSSProperties, useMemo } from "react";
import { Avatar } from "@radix-ui/themes";
import { QuackLogo } from "../assets/QuackLogo";

interface WidgetSVGProps {
  path: string;
  iconSize: number;
  initials?: string;
  hide?: boolean;
}

export function WidgetSVG({
  path,
  iconSize,
  initials,
  hide = false,
}: WidgetSVGProps) {
  return (
    <Avatar
      color={"gray"}
      src={path}
      fallback={initials ?? <QuackLogo />}
      className={"qc-Chat__Custom_SVG"}
      style={
        {
          "--avatar-size": `${iconSize}px`,
          display: hide ? "none" : "flex",
        } as CSSProperties
      }
    />
  );
}

interface MessageAvatarSVGProps {
  path?: string;
  kind?: "agent" | "user";
  initials?: string;
}

export function MessageAvatarSVG({
  path,
  kind,
  initials,
}: MessageAvatarSVGProps) {
  const styles = useMemo<CSSProperties>(() => {
    if (kind === "agent") {
      return {
        "--accent-a11": "var(--agentText)",
        "--accent-a3": "var(--agentBackground)",
      } as CSSProperties;
    } else if (kind === "user") {
      return {
        "--accent-a11": "var(--userText)",
        "--accent-a3": "var(--userBackground)",
      } as CSSProperties;
    } else {
      return {};
    }
  }, [kind]);

  return (
    <Avatar
      size={"1"}
      color={"gray"}
      src={path}
      fallback={initials ?? <QuackLogo />}
      className={"qc-Chat__Custom_SVG"}
      style={styles}
    />
  );
}
