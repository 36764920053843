import { useMutation } from "react-query";
import { useApi } from "./useApi";
import { AxiosResponse } from "axios";

interface EscalationEmailParams {
  chatSessionId: string;
  email: string;
}

export function useEscalationEmail() {
  const { api } = useApi();

  return useMutation(async (params: EscalationEmailParams) => {
    return api.post<void, AxiosResponse<void>, EscalationEmailParams>(
      "/sessions-api/quackchat/escalation-email",
      {
        ...params,
      }
    );
  });
}
