import { Flex, Button, Text, Em } from "@radix-ui/themes";
import React from "react";

interface StartNewChatProps {
  onClick: () => Promise<void>;
}

export function StartNewChat({ onClick }: StartNewChatProps) {
  return (
    <Flex px={"3"} py={"4"}>
      <Flex
        width={"100%"}
        px={"3"}
        py={"2"}
        direction={"column"}
        gap={"2"}
        style={{ backgroundColor: "var(--gray-2)", borderRadius: "8px" }}
      >
        <Text size={"2"}>
          <Em>The conversation has ended</Em>
        </Text>

        <div>
          <Button
            size={"1"}
            color={"gray"}
            variant={"solid"}
            highContrast
            onClick={onClick}
          >
            Start New Chat
          </Button>
        </div>
      </Flex>
    </Flex>
  );
}
