import { z } from "zod";

export enum ESocketEvents {
  NEW_CONSOLE = "new_console",
  NEW_EVENT = "new_event",
  NEW_NETWORK = "new_network",
  NEW_LOCALSTORAGE = "new_localstorage",
  NEW_RECORDING = "new_recording",
  PAGE_VIEW = "page_view",
  DISCONNECT = "disconnect",
  CONNECTION = "connection",
  IDENTIFY = "identify",
}

export interface ISDKSettings {
  tenantId: string;
  version?: "v1" | "v2";
  isActive: boolean;
  isTokenMasked: boolean;
  isNetworkBodyDisabled?: boolean;
  chat?: boolean;
  openChatDefaultList: string[];
  whiteListUrls: string[];
  proactiveListUrls: string[];
  proactiveTime: number;
  network?: boolean;
  console?: boolean;
  events?: boolean;
  pageViews?: boolean;
  recordings?: boolean;
  inject: boolean;
}

export type Theme = {
  iconPrimary: string;
  widgetPrimary: string;
  background: string;
  lightBorder: string;
  textPlaceholder: string;
  borderDefault: string;
  bgCard: string;
  bgInverted: string;
  bgPrimaryQuack: string;
  borderLight: string;
  textPrimary: string;
  textInverted: string;
  bgInput: string;
  userBackground: string;
  userText: string;
  agentBackground: string;
  agentText: string;
  bgChatWidget: string;
  bgSendButton?: string;
  textSendButton?: string;
};

export type ChatSettings = {
  name: string;
  quackAgentName: string;
  theme: Theme;
  customSVG: string;
  closeSVG?: string;
  avatarSVG?: string;
  borderRadius: number;
  iconSize: number;
  position?: "bottom-left" | "bottom-right";
  iconPadding: number;
  poweredByLine?: boolean;
  firstMessage?: string;
  openFullyOnOpenChat?: boolean;
  disappearAfterClose?: number;
  fontSize?: number;
  waitTimeBeforeRefreshAfterEscalating?: number;
  escalationMessageToLiveAgent: string;
  escalationMessageToNotLiveAgent: string;
  placeholder?: string;
  fallbackSupportEmail?: string;
  csatDisabled?: boolean;
  abandonedChatQuestion?: {
    waitTime: number;
    question: string;
  };
};

export type QuackChatConfig = ISDKSettings & {
  chatSettings: ChatSettings;
};

export type QuackChatConfigResponse = ISDKSettings & {
  chatSettings: Omit<
    ChatSettings,
    | "quackAgentName"
    | "theme"
    | "customSVG"
    | "borderRadius"
    | "iconSize"
    | "iconPadding"
    | "escalationMessageToLiveAgent"
    | "escalationMessageToNotLiveAgent"
  > & {
    quackAgentName?: string;
    theme?: Theme;
    customSVG?: string;
    borderRadius?: number;
    iconSize?: number;
    iconPadding?: number;
    escalationMessageToLiveAgent?: string;
    escalationMessageToNotLiveAgent?: string;
  };
};

export const quackChatConfigSchema = z
  .object({
    tenantId: z.string(),
    version: z.enum(["v1", "v2"]).optional(),
    isActive: z.boolean(),
    isTokenMasked: z.boolean(),
    isNetworkBodyDisabled: z.boolean().optional(),
    chat: z.boolean(),
    openChatDefaultList: z.array(z.string()),
    whiteListUrls: z.array(z.string()),
    proactiveListUrls: z.array(z.string()),
    proactiveTime: z.number(),
    network: z.boolean().optional(),
    console: z.boolean().optional(),
    events: z.boolean().optional(),
    pageViews: z.boolean().optional(),
    recordings: z.boolean().optional(),
    inject: z.boolean(),
    chatSettings: z
      .object({
        name: z.string(),
        quackAgentName: z.string().optional(),
        theme: z
          .object({
            iconPrimary: z.string(),
            widgetPrimary: z.string(),
            background: z.string(),
            lightBorder: z.string(),
            textPlaceholder: z.string(),
            borderDefault: z.string(),
            bgCard: z.string(),
            bgInverted: z.string(),
            bgPrimaryQuack: z.string(),
            borderLight: z.string(),
            textPrimary: z.string(),
            textInverted: z.string(),
            bgInput: z.string(),
            userBackground: z.string(),
            userText: z.string(),
            agentBackground: z.string(),
            agentText: z.string(),
            bgChatWidget: z.string(),
            bgSendButton: z.string().optional(),
            textSendButton: z.string().optional(),
          })
          .strict()
          .optional(),
        customSVG: z.string().optional(),
        closeSVG: z.string().optional(),
        avatarSVG: z.string().optional(),
        borderRadius: z.number().optional(),
        iconSize: z.number().optional(),
        position: z.enum(["bottom-left", "bottom-right"]).optional(),
        iconPadding: z.number().optional(),
        poweredByLine: z.boolean().optional(),
        firstMessage: z.string().optional(),
        openFullyOnOpenChat: z.boolean().optional(),
        disappearAfterClose: z.number().optional(),
        fontSize: z.number().optional(),
        waitTimeBeforeRefreshAfterEscalating: z.number().optional(),
        escalationMessageToLiveAgent: z.string().optional(),
        escalationMessageToNotLiveAgent: z.string().optional(),
        placeholder: z.string().optional(),
        fallbackSupportEmail: z.string().optional(),
        csatDisabled: z.boolean().optional(),
        abandonedChatQuestion: z
          .object({
            waitTime: z.number(),
            question: z.string(),
          })
          .strict()
          .optional(),
      })
      .strict(),
  })
  .strict();
