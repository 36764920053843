import dayjs from "dayjs";

export const getBackupValuesFromLS = () => {
  const state = localStorage.getItem("quack-backup");
  if (state) {
    const jsonState = JSON.parse(state);

    if (dayjs().valueOf() - 1000 * 30 <= +jsonState.timestamp) {
      // should restore
      return jsonState as {
        recordingEvents: any[];
        network: any[];
        consoleLogs: any[];
        events: any[];
        correlationId: string;
        anonId: string;
      };
    }
  }

  return {
    recordingEvents: [],
    network: [],
    consoleLogs: [],
    events: [],
    correlationId: null,
    anonId: state ? JSON.parse(state).anonId : null,
  };
};
