import React, { useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Message } from "ai/react/dist";
import { useAiChat } from "../api/useChat";
import { useHandleUrlChange } from "../hooks/useHandleUrlChange";
import { API_URL } from "../constants";
import { ChatProps } from "../chat";
import { useInterval } from "../hooks/useInterval";
import { getChatConversation } from "../api/useChatConversation";

import { StatusUpdateResult } from "./types";
import { MessagesContainer } from "./messagesContainer";
import { EMessagesContainerType, TypeContext } from "./typeContext";

declare global {
  interface Window {
    quack: {
      email: string;
      addErrorHandler: (callback: (error: unknown) => void) => void;
      errorRepotingCallback: (error: unknown) => void;
    };
  }
}

window.quack = window.quack || {};

export const ChatComponent = ({
  tenantId,
  sessionId,
  token,
  whiteListUrls = [],
  proactiveListUrls = [],
  track = () => {},
  email,
  showChatTime,
  proactiveTime,
  chatSettings,
}: ChatProps & { showChatTime: string }) => {
  const [showInput, setShowInput] = useState(false);
  const [chatId, setChatId] = useState<string | null>(null);

  const [showProactive, setShowProactive] = useState(false);
  const [escalation, setEscalation] = useState(false);
  const [isLiveChat, setLiveChat] = useState(false);
  const [shouldPoll, setShouldPolling] = useState(false);

  const conversationMessages = useRef<Message[]>([]);

  const chatSessionId = useRef<string | null>(null);
  const messagesRef = useRef<Message[]>([]);

  const fetchEmail = () => {
    const tempEmail = window?.["quack"]?.email ?? "";

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(tempEmail)) {
      return tempEmail;
    }
    return "";
  };

  useEffect(() => {
    setEscalation(false);
    chatSessionId.current = chatId;
  }, [chatId]);

  const updateCSAT = async (score: number) => {
    const response = await fetch(`${API_URL}sessions-api/chat-session/csat`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        score: score,
        comment: "",
        chatSessionId: chatSessionId.current,
      }),
    });

    setTimeout(() => {
      setChatId(null);
    }, 5000);
  };

  const handleFixMessage = async (message: Message) => {
    await fetch(`${API_URL}sessions-api/chat-session/fixed-message`, {
      body: JSON.stringify({
        messages: [message],
        chatSessionId: chatSessionId.current,
        tenant: noSandboxTenant,
      }),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    });
  };

  const handleEscalate = async (email: string) => {
    try {
      const response = await fetch(
        `${API_URL}sessions-api/chat-session/status`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            sessionId,
            chatSessionId: chatSessionId.current,
            tenant: noSandboxTenant,
            status: "escalate",
            userEmail: email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to escalate");
      }

      const res: { statusUpdateResult: StatusUpdateResult } =
        await response.json();

      switch (res.statusUpdateResult) {
        case StatusUpdateResult.CREATE_TICKET:
        case StatusUpdateResult.CREATE_TICKET_NO_ONLINE_AGENTS:
          setQuestion({ target: { value: "" } } as any);
          setEscalation(false);

          // check append, or check fixed-message

          appendAndSave({
            role: "assistant",
            content:
              chatSettings.escalationMessageToNotLiveAgent ??
              "Got it, we have your request and an agent will reply by email soon. We usually reply in less then 24 hours",
            id: "22343",
          });
          // setMessages([
          //   ...messagesRef.current,
          //   {
          //     role: "assistant",
          //     content:
          //       chatSettings.escalationMessageToNotLiveAgent ??
          //       "Got it, we have your request and an agent will reply by email soon. We usually reply in less then 24 hours",
          //     id: "22343",
          //   },
          // ]);
          setTimeout(() => {
            setChatId(null);
          }, (chatSettings.waitTimeBeforeRefreshAfterEscalating ?? 3) * 1000);
          break;
        case StatusUpdateResult.DO_NOTHING:
          break;
        case StatusUpdateResult.ESCALATE_TO_LIVE_AGENT:
          setQuestion({ target: { value: "" } } as any);
          // setMessages([
          //   ...messagesRef.current,
          //   {
          //     role: "assistant",
          //     content: chatSettings.escalationMessageToLiveAgent,
          //     id: "22343",
          //   },
          // ]);

          appendAndSave({
            role: "assistant",
            content: chatSettings.escalationMessageToLiveAgent,
            id: "22343",
          });

          setShouldPolling(true);
          setEscalation(false);
          break;
      }

      // here should present message to user, and close chat
    } catch (error) {
      console.error("Error during escalation:", error);
    }
  };
  const {
    messages,
    input: question,
    handleInputChange: setQuestion,
    handleSubmit,
    isLoading: aiLoading,
    setMessages,
    isTyping,
    events,
    append,
  } = useAiChat({
    chatId: chatId ?? "",
    tenant: tenantId,
    track,
    showProactive,
    csatDisabled: chatSettings.csatDisabled ?? false,
    firstMessage: chatSettings.firstMessage,
    fetchEmail,
    escalationMessageToNotLiveAgent:
      chatSettings.escalationMessageToNotLiveAgent,
    setEscalation: setEscalation,
    handleEscalate,
    handleFixMessage,
    abandonedChatQuestion: chatSettings.abandonedChatQuestion,
  });

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const messageRef = useRef<boolean>(false);

  const appendAndSave = async (message: Message) => {
    await handleFixMessage(message);
    setMessages((prev: Message[]) => [...prev, message]);
  };

  useInterval(
    () => {
      getChatConversation({
        tenantId: tenantId,
        chatSessionId: chatId ?? "",
      }).then((res) => {
        setChatId(chatId);
        setLiveChat(true);

        const indexOfEmptyAssistantMessage = res.chatMessages.findIndex(
          (message: any) =>
            message.role === "assistant" && message.content === ""
        );

        if (conversationMessages.current.length == 0) {
          conversationMessages.current = messages;
        }

        setMessages(() => [
          ...conversationMessages.current,
          ...res.chatMessages.slice(indexOfEmptyAssistantMessage + 1),
        ]);
      });
    },
    shouldPoll ? 2000 : null
  );

  const noSandboxTenant = tenantId.replace("-sandbox", "");

  const [type, setType] = useState<EMessagesContainerType>(
    chatSettings.openFullyOnOpenChat || messages.length !== 0
      ? EMessagesContainerType.CHAT
      : EMessagesContainerType.REST
  );

  const { showChat } = useHandleUrlChange({
    whiteListUrls,
    proactiveListUrls,
    type,
    messages,
    showInput,
    setType,
    setShowInput,
    showChatTime,
    proactiveTime,
    disappearAfterClose: chatSettings.disappearAfterClose,
  });

  const onMessage = async () => {
    setType(EMessagesContainerType.CHAT);

    if (!chatId) {
      try {
        const response = await fetch(`${API_URL}sessions-api/chat-session`, {
          body: JSON.stringify({
            sessionId,
            tenant: noSandboxTenant,
          }),
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
        });
        const { chatSessionId: chatSessionIdValue } = await response.json();
        chatSessionId.current = chatSessionIdValue;
        setChatId(chatSessionIdValue);
        await events.trackChatSessionStart(chatSessionIdValue);
        if (chatSettings.firstMessage) {
          const response = await fetch(
            `${API_URL}sessions-api/chat-session/fixed-message`,
            {
              body: JSON.stringify({
                messages: [
                  {
                    role: "assistant",
                    content: chatSettings.firstMessage,
                  },
                ],
                chatSessionId: chatSessionIdValue,
                tenant: noSandboxTenant,
              }),
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
            }
          );
          const data = await response.json();
        }

        if (showProactive) {
          await appendAndSave({
            id: "proactive",
            content: "Hi, is there anything I can help you with?",
            role: "assistant",
          });
        }
      } catch (err) {
        // fire sentry
        // do something
      }
    } else {
      events.trackMessageSent(true);
    }
  };

  return (
    <ThemeProvider
      theme={{
        light: chatSettings.theme,
        dark: chatSettings.theme,
        borderRadius: chatSettings.borderRadius,
        iconSize: chatSettings.iconSize,
        iconPadding: chatSettings.iconPadding,
      }}
    >
      <TypeContext.Provider
        value={{
          type,
          token,
          tenant: tenantId,
          email: (window as any)?.quack?.email ?? email,
          events,
        }}
      >
        {showChat && (
          <ChatComponentWrapper
            className={`${showInput ? "showInput" : ""} ${
              showInput && type === EMessagesContainerType.CHAT
                ? "messageContainerWidget"
                : ""
            } ${chatSettings.position === "bottom-left" ? "left" : ""}`}
          >
            {showInput && (
              <MessagesContainer
                title={chatSettings.name}
                onMessage={onMessage}
                closeWidget={() => {
                  if (type === EMessagesContainerType.PROACTIVE)
                    setType(EMessagesContainerType.REST);
                  setShowInput(false);
                  events.trackChatButtonClicked(false);
                }}
                messages={
                  isLiveChat
                    ? messages.filter(
                        (msg: Message, index: number, arr: Message[]) =>
                          index === 0 ||
                          msg.content !== arr[index - 1].content ||
                          index === arr.length - 1 ||
                          msg.content !== arr[index + 1].content
                      )
                    : messages
                }
                question={question}
                setQuestion={setQuestion}
                handleSubmit={handleSubmit}
                aiLoading={aiLoading}
                setMessages={setMessages}
                isTyping={isTyping}
                handleEscalate={handleEscalate}
                showProactive={showProactive}
                setShowProactive={setShowProactive}
                escalation={escalation}
                poweredByLine={chatSettings.poweredByLine}
                fontSize={chatSettings.fontSize ?? 16}
                fetchEmail={fetchEmail}
                updateCSAT={updateCSAT}
                appendAndSave={appendAndSave}
                trackProactive={events.trackProactive}
              />
            )}
            <div
              className="basicChatWidget"
              onClick={() => {
                setShowInput((prev) => {
                  events.trackChatButtonClicked(!prev);
                  return !prev;
                });
              }}
            >
              <div
                className="htmlSvg"
                dangerouslySetInnerHTML={{ __html: chatSettings.customSVG }}
              />
            </div>
          </ChatComponentWrapper>
        )}
      </TypeContext.Provider>
    </ThemeProvider>
  );
};

const ChatComponentWrapper = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100000000;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Inter;
  width: fit-content;

  &.showInput {
    width: 400px;
    max-width: 400px;
  }

  &.left {
    right: auto !important;
    left: 24px !important;

    .basicChatWidget {
      align-self: flex-start !important;
    }
  }

  @media (max-width: 768px) {
    &.showInput {
      width: 360px;
      max-width: 360px;
    }
  }

  &.messageContainerWidget {
    /* height: 100%; */
    /* max-height: 728px; */
    /* overflow: hidden; */
  }

  .basicChatWidget {
    border-radius: ${(props) => `${props.theme.borderRadius}px`};
    background: ${(props) => props.theme.light.bgChatWidget};
    display: flex;
    padding: ${(props) => `${props.theme.iconPadding}px`};
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-self: flex-end;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      scale: 1.1;
    }

    .htmlSvg {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.light.widgetPrimary};
    }

    svg {
      width: ${(props) => `${props.theme.iconSize}px`};
      height: ${(props) => `${props.theme.iconSize}px`};
      color: ${(props) => props.theme.light.widgetPrimary};
    }
  }

  /* Custom dark scrollbar styles to mimic macOS look */
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    border: 3px solid transparent !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #5e5e5e !important;
  }
`;
