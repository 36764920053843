import React, { CSSProperties, PropsWithChildren, useMemo } from "react";
import { Theme } from "@radix-ui/themes";
import { ThemV2 as QuackThemeVars } from "../entities/ChatSettingsV2";

interface QuackChatWidgetThemeProps {
  themeVars: QuackThemeVars;
}

export function QuackChatWidgetTheme({
  children,
  themeVars,
}: PropsWithChildren<QuackChatWidgetThemeProps>) {
  const themeParams = useMemo<CSSProperties>(() => {
    const params = Object.fromEntries(
      Object.entries(themeVars).map(([key, value]) => {
        return [`--${key}`, value];
      })
    );

    return params;
  }, [themeVars]);

  return (
    <Theme
      className="qc-themes light"
      accentColor={"gray"}
      grayColor={"slate"}
      style={themeParams}
      radius={"medium"}
    >
      {children}
    </Theme>
  );
}
