import { Dict } from "mixpanel-browser";
import axios from "axios";

export enum MixpanelEvents {
  ChatbotSessionStart = "chatbot_session_start",
  ChatButtonClicked = "chat_button_clicked",
  UserMessageSent = "user_message_sent",
  BotMessageSent = "bot_message_sent",
  ChatbotViewed = "chatbot_viewed",
  ButtonClicked = "cta_button_clicked",
  PROACTIVE_TRIGGERED = "chatbot_proactive",
}

declare global {
  interface Window {
    analytics: any;
    track_properties: any;
  }
}

export const mixTrackEvent = async ({
  tenant,
  sessionId,
  properties,
  event,
}: {
  tenant: string;
  sessionId: string;
  properties: Dict;
  event: MixpanelEvents;
}) => {
  const response = await axios.post(
    "https://api.segment.io/v1/track",
    {
      event: `Quackchat_${event}`,
      tenant,
      email: `${tenant}@quack.ai`,
      userId: tenant,
      properties: { ...properties, event: `Quackchat_${event}`, sessionId },
      writeKey: "m2rVbmVbAeNg1OMAKE3D5dDI6yjhuhSA",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Network response was not ok");
  }

  return response.data;
};
