import { createContext } from "react";

export enum EMessagesContainerType {
  REST = "rest",
  PROACTIVE = "proactive",
  CHAT = "chat",
}

export const TypeContext = createContext<{
  type: EMessagesContainerType;
  token: string;
  tenant: string;
  email: string;
  escalationMessageToLiveAgent?: string;
  escalationMessageToNotLiveAgent?: string;
  events: {
    trackChatButtonClicked: (opened: boolean) => void;
    trackCTAButtonClicked: (buttonName: string) => void;
  };
}>({
  type: EMessagesContainerType.PROACTIVE,
  token: "",
  tenant: "",
  email: "",
  events: {
    trackChatButtonClicked: () => {},
    trackCTAButtonClicked: () => {},
  },
});
