import { useMutation } from "react-query";
import { useApi } from "./useApi";

interface CSATRequest {
  chatSessionId: string;
  score: number;
  comment?: string;
}

export function useCSAT() {
  const { api } = useApi();

  return useMutation(async (data: CSATRequest) => {
    await api.post<void, void, CSATRequest>(
      `/sessions-api/quackchat/csat`,
      data
    );

    return data.score;
  });
}
