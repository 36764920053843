import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { useApi } from "./useApi";
import { Message } from "../core/entities/Message";

interface SendMessageParams {
  messages: {
    author: "USER";
    text: string;
    data?: {
      attachmentUrl?: string; // not yet supported
    };
  }[];
  chatSessionId: string;
  tenant: string;
}

interface SendMessageResponse {
  deduplicationKey: string;
}

export function useSendMessage(tenant: string, chatSessionId?: string) {
  const { api } = useApi();

  return useMutation(async (message: Message) => {
    if (chatSessionId == null) {
      return;
    }

    return api.post<
      SendMessageResponse,
      AxiosResponse<SendMessageResponse>,
      SendMessageParams
    >("/sessions-api/quackchat/message", {
      tenant,
      chatSessionId,
      messages: [
        {
          author: "USER",
          text: message.text,
        },
      ],
    });
  });
}
