import React, { useMemo, PropsWithChildren } from "react";
import { Box, Card, Separator } from "@radix-ui/themes";
import { ChatSettingsV2 } from "../entities/ChatSettingsV2";
import { Message } from "../entities/Message";
import { Header } from "./Header";
import { Messages } from "./Messages";
import { Input } from "./Input";
import { Footer } from "./Footer";
import { StartNewChat } from "./StartNewChat";
import { QuackChatError } from "./QuackChatError";

function emptyCallback() {}

function emptyAsyncCallback(): Promise<void> {
  return Promise.resolve();
}

interface QuackChatCardProps {
  settings: ChatSettingsV2;
}

function QuackChatCard({
  children,
  settings: { poweredByLine },
}: PropsWithChildren<QuackChatCardProps>) {
  return (
    <Box className="qc-Chat__Widget">
      <Card
        variant="classic"
        size={"5"}
        style={{
          height: "100%",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
        className="qc-Chat__Widget__Card"
      >
        {children}

        {poweredByLine && (
          <>
            <Separator orientation="horizontal" size="4" />
            <Footer />
          </>
        )}
      </Card>
    </Box>
  );
}

interface QuackChatCompProps {
  settings: ChatSettingsV2;
  agents?: string[];
  messages?: Message[];
  showCSAT?: boolean;
  thinking?: boolean;
  csatScore?: number;
  chatEnded?: boolean;
  userSent?: boolean;
  isError?: boolean;
  sendMessage?: (message: string) => void;
  startNewChat?: () => Promise<void>;
  onClose?: () => void;
  onCSATScore?: (score: number) => void;
  uploadFile?: (file: File) => Promise<any>;
  onEscalate?: () => Promise<void>;
  onEscalationEmail?: (email: string) => Promise<void>;
}

export function QuackChatComp({
  settings,
  agents = [],
  messages = [],
  showCSAT,
  thinking,
  csatScore,
  chatEnded = false,
  isError,
  sendMessage = emptyCallback,
  startNewChat = emptyAsyncCallback,
  onClose,
  onCSATScore = emptyCallback,
  uploadFile = emptyAsyncCallback,
  onEscalate = emptyAsyncCallback,
  onEscalationEmail = emptyAsyncCallback,
}: QuackChatCompProps) {
  const { name, quackAgentName, customSVG, avatarSVG, placeholder } = settings;

  const isAgentJoined = useMemo(() => agents.length > 0, [agents]);
  const userSent = useMemo(
    () => messages.some(({ author }) => author === "USER"),
    [messages]
  );

  return (
    <QuackChatCard settings={settings}>
      {isError ? (
        <QuackChatError settings={settings} onClose={onClose} />
      ) : (
        <>
          <Header
            name={name}
            quackAgentName={quackAgentName}
            onClose={onClose}
            agents={agents}
          />

          <Separator orientation="horizontal" size="4" />

          <Messages
            messages={messages}
            showCSAT={showCSAT}
            customSVG={customSVG}
            avatarSVG={avatarSVG}
            thinking={thinking}
            csatScore={csatScore}
            onCSATScore={onCSATScore}
            onEscalate={onEscalate}
            onEscalationEmail={onEscalationEmail}
          />

          {!chatEnded && (
            <>
              <Separator orientation="horizontal" size="4" />
              <Input
                allowUploadFile={isAgentJoined}
                placeholder={userSent ? undefined : placeholder}
                sendMessage={sendMessage}
                uploadFile={uploadFile}
              />
            </>
          )}

          {chatEnded && <StartNewChat onClick={startNewChat} />}
        </>
      )}
    </QuackChatCard>
  );
}
