import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import axios, { AxiosInstance } from "axios";
import { API_URL } from "../../constants";
import { useSentryScope } from "../hooks/useSentryScope";

const ApiContext = createContext<AxiosInstance | null>(null);

export function ApiProvider({ children }: PropsWithChildren) {
  const sentryScope = useSentryScope();

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: API_URL,
      timeout: 10000, // optional timeout
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Optional: Add request interceptor
    instance.interceptors.request.use(
      (config) => {
        // Further customization if needed (e.g., dynamically attach tokens)
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Optional: Add response interceptor
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const foramttedError = {
          ...error,
          name: `ApiError:  ${error.config.url}`,
        };
        sentryScope?.captureException(foramttedError);
        window?.quack?.errorRepotingCallback?.(foramttedError);

        // Handle errors globally
        return Promise.reject(error);
      }
    );

    return instance;
  }, [sentryScope]);

  return (
    <ApiContext.Provider value={api}>
      {api != null && children}
    </ApiContext.Provider>
  );
}

export function useApi(): { api: AxiosInstance } {
  const api = useContext(ApiContext);

  if (api == null) {
    throw new Error("useApi must be used within an ApiProvider");
  }

  return { api };
}
