import { Message } from "ai/react";
import { useEffect, useRef, useState } from "react";

import { MixpanelEvents } from ".";

type MixpanelEvent = {
  tenant: string;
  section: string;
  tab: string;
  chatId: string | null;
  isProactive?: boolean;
  event: string;
  entry_page?: string;
  message_content?: string;
  opened?: boolean;
  button_name?: string;
};

export const useEvents = ({
  messages,
  lastMessageId,
  loading,
  track,
  chatId,
  tenant,
  showProactive,
}: {
  messages: Message[];
  lastMessageId: string | null;
  loading: boolean;
  track: (eventName: string, properties: any) => void;
  chatId: string;
  tenant: string;
  showProactive: boolean;
}) => {
  const hasChatbotViewed = useRef<boolean>(false);

  const isProactiveSession = useRef<boolean>(false);

  useEffect(() => {
    if (showProactive) {
      isProactiveSession.current = true;
    }
  }, [showProactive]);

  useEffect(() => {
    if (!hasChatbotViewed.current) {
      track(MixpanelEvents.ChatbotViewed, {
        tenant,
        entry_page: window.location.pathname,
        chatId,
        event: MixpanelEvents.ChatbotViewed,
      } as MixpanelEvent);
      hasChatbotViewed.current = true;
    }
  }, []);

  const trackChatButtonClicked = (opened: boolean) => {
    track(MixpanelEvents.ChatButtonClicked, {
      tenant,
      opened,
      chatId,
      isProactive: isProactiveSession.current,
      event: MixpanelEvents.ChatButtonClicked,
    } as MixpanelEvent);
  };

  const trackCTAButtonClicked = (buttonName: string) => {
    track(MixpanelEvents.ButtonClicked, {
      tenant,
      button_name: buttonName,
      chatId,
      isProactive: isProactiveSession.current,
      event: MixpanelEvents.ButtonClicked,
    } as MixpanelEvent);
  };

  const trackChatSessionStart = (customChatId: unknown) => {
    track(MixpanelEvents.ChatbotSessionStart, {
      tenant,
      entry_page: window.location.pathname,
      chatId: customChatId,
      isProactive: isProactiveSession.current,
      event: MixpanelEvents.ChatbotSessionStart,
    } as MixpanelEvent);
  };

  const trackMessageSent = (isUser: boolean) => {
    track(
      isUser ? MixpanelEvents.UserMessageSent : MixpanelEvents.BotMessageSent,
      {
        tenant,
        entry_page: window.location.pathname,
        chatId,
        isProactive: isProactiveSession.current,
        event: isUser
          ? MixpanelEvents.UserMessageSent
          : MixpanelEvents.BotMessageSent,
      } as MixpanelEvent
    );
  };
  const trackProactive = () => {
    if (!isProactiveSession.current) {
      track(MixpanelEvents.PROACTIVE_TRIGGERED, {
        tenant,
        entry_page: window.location.pathname,
        chatId,
        isProactive: true,
        event: MixpanelEvents.PROACTIVE_TRIGGERED,
      } as MixpanelEvent);
    }
  };

  return {
    trackChatButtonClicked,
    trackCTAButtonClicked,
    trackChatSessionStart,
    trackMessageSent,
    trackProactive,
  };
};
