import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { Badge, Flex, IconButton, TextArea } from "@radix-ui/themes";
import { ArrowUpIcon, UploadIcon, Cross1Icon } from "@radix-ui/react-icons";

interface InputProps {
  placeholder?: string;
  allowUploadFile: boolean;
  sendMessage: (message: string) => void;
  uploadFile: (file: File) => Promise<void>;
}

export function Input({
  placeholder = "Reply...",
  allowUploadFile,
  sendMessage,
  uploadFile,
}: InputProps) {
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const sendDisabled = useMemo(
    () =>
      ((message.length === 0 || message.trim() === "") && files.length === 0) ||
      sending,
    [message, files, sending]
  );

  const handleSendMessage = useCallback(async () => {
    setSending(true);

    if (message.trim()) {
      sendMessage(message);
      setMessage("");
    }

    if (files.length) {
      // Handle the file upload logic here
      const promises = files.map((file) => uploadFile(file));

      await Promise.allSettled(promises);
      setFiles([]);
    }

    setSending(false);
  }, [message, sendMessage, files, uploadFile]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      if (!isMobile && event.key === "Enter" && !event.shiftKey) {
        handleSendMessage();
        event.preventDefault();
      }
    },
    [handleSendMessage]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value);
    },
    []
  );

  const handleFileUpload = useCallback(() => {
    if (allowUploadFile) {
      const input = document.createElement("input");
      input.type = "file";
      input.onchange = (event) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
          setFiles((prevFiles) => [...prevFiles, file]);
        }
      };
      input.click();
    }
  }, [allowUploadFile]);

  const handleRemoveFile = useCallback((file: File) => {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
  }, []);

  useEffect(() => {
    // Reset the height to get the new scrollHeight
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      // Set the height to match the scrollHeight
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [message]);

  return (
    <Flex py={"1"} px={"2"} direction={"column"}>
      <Flex py={"2"} px={"3"} direction={"column"} gap={"1"}>
        <Flex gap={"2"} justify={"between"} align={"end"}>
          <TextArea
            placeholder={placeholder}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={{
              flexGrow: "1",
              boxShadow: "none",
              outline: "none",
            }}
            className="qc-Chat__Input"
            ref={textareaRef}
          />

          <IconButton
            color="gray"
            variant="solid"
            radius="full"
            onClick={handleSendMessage}
            disabled={sendDisabled}
            loading={sending}
            className="qc-Chat__SendButton"
          >
            <ArrowUpIcon width="15" height="15" />
          </IconButton>
        </Flex>

        {allowUploadFile && (
          <Flex gap={"2"} align={"center"} minHeight={"20px"}>
            <IconButton
              size={"1"}
              color="gray"
              variant="ghost"
              onClick={handleFileUpload}
            >
              <UploadIcon width="15" height="15" />
            </IconButton>

            {files.map((file) => (
              <Badge key={file.name} size="1">
                {file.name.slice(0, 10)}...
                <IconButton
                  size="1"
                  color="gray"
                  variant="ghost"
                  onClick={() => handleRemoveFile(file)}
                >
                  <Cross1Icon width="10" height="10" />
                </IconButton>
              </Badge>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
