import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ChatSettingsV2 } from "./core/entities/ChatSettingsV2";
import { ErrorMessage, Message } from "./core/entities/Message";
import {
  clearChatSessionResponseFromLocalStorage,
  useChatSession,
} from "./api/useChatSession";
import {
  ConnectionRef,
  useConnectChatSession,
} from "./api/useConnectChatSession";
import { useSendMessage } from "./api/useSendMessage";
import { useHealth } from "./api/useHealth";
import { useRestoreChatMessages } from "./hooks/useRestoreChatMessages";
import { useMessageHandler } from "./useMessageHandler";
import { ConnectionStatus } from "./ConnectionStatus";
import { QuackChatComp } from "./core/parts/QuackChatComp";
import { useCSAT } from "./api/useCSAT";
import { useUploadFile } from "./api/useUploadFile";
import { useEscalate } from "./api/useEscalate";
import { useEscalationEmail } from "./api/useEscalationEmail";
import { QuackChatCompContainer } from "./core/parts/QuackChatCompContainer";

const isDev = false;

interface QuackChatProps {
  token: string;
  tenant: string;
  settings: ChatSettingsV2;
  initialized: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function QuackChat({
  tenant,
  settings,
  initialized,
  open,
  onOpenChange,
}: QuackChatProps) {
  const healthQuery = useHealth(initialized);

  const {
    data: chatSession,
    isLoading,
    stateNewChateSession,
  } = useChatSession(tenant, initialized);

  const { mutateAsync: sendMessage } = useSendMessage(
    tenant,
    chatSession?.chatSessionId
  );

  const csatMutation = useCSAT();
  const uploadFileMutation = useUploadFile();
  const esclateMutation = useEscalate();
  const esclationEmailMutation = useEscalationEmail();

  const [messages, setMessages] = useState<Message[]>([]);
  const [chatEnded, setChatEnded] = useState(false);
  const [showCSAT, setShowCSAT] = useState(false);
  const [thinking, setThinking] = useState(false);
  const [agents, setAgents] = useState<string[]>([]);

  const connectionRef = useRef<ConnectionRef>(null);

  const isAgentJoined = useMemo(() => {
    return (
      agents.length > 0 ||
      messages.some(({ author }) => author === "LIVE_AGENT")
    );
  }, [agents, messages]);

  const onClose = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  const onCSATScore = useCallback(
    (score: number) => {
      if (chatSession == null) {
        return;
      }

      csatMutation.mutateAsync({
        chatSessionId: chatSession.chatSessionId,
        score,
      });
    },
    [chatSession?.chatSessionId, csatMutation.mutateAsync]
  );

  const onUploadFile = useCallback(
    async (file: File) => {
      if (chatSession == null) {
        return;
      }

      const res = await uploadFileMutation.mutateAsync({
        chatSessionId: chatSession.chatSessionId,
        file,
      });

      return res;
    },
    [chatSession?.chatSessionId, uploadFileMutation.mutateAsync]
  );

  const onEscalate = useCallback(async () => {
    if (chatSession == null) {
      return;
    }

    esclateMutation.mutate(chatSession.chatSessionId);
  }, [chatSession?.chatSessionId, esclateMutation.mutate]);

  const onEscalationEmail = useCallback(
    async (email: string) => {
      if (chatSession == null) {
        return;
      }

      esclationEmailMutation.mutate({
        chatSessionId: chatSession.chatSessionId,
        email,
      });
    },
    [chatSession?.chatSessionId, esclationEmailMutation.mutate]
  );

  const addMessage = useCallback((message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
    setThinking(false);
  }, []);

  const handleChatClosed = useCallback(
    (showCSAT: boolean) => {
      clearChatSessionResponseFromLocalStorage(tenant);
      connectionRef.current?.closeConnection();
      setChatEnded(true);

      if (showCSAT) {
        setShowCSAT(true);
      }
    },
    [tenant]
  );

  const handleStartNewChat = useCallback(async () => {
    setMessages([]);
    setChatEnded(false);
    setShowCSAT(false);
    setThinking(false);
    setAgents([]);

    clearChatSessionResponseFromLocalStorage(tenant);

    const res = await stateNewChateSession();
    if (res.data?.chatSessionId != null) {
      connectionRef.current?.startConnection(res.data);
    }
  }, [tenant, stateNewChateSession]);

  const handleUserSendMessage = useCallback(
    (messageText: string) => {
      const localDeduplicationKey = uuidv4();

      const message: Message = {
        action: "standard_message",
        author: "USER",
        deduplicationKey: localDeduplicationKey,
        text: messageText,
        createdAt: new Date().toISOString(),
        data: {},
      };

      addMessage(message);
      sendMessage(message).then((res) => {
        if (res?.data?.deduplicationKey != null) {
          const serverDeduplicationKey = res.data.deduplicationKey;

          // Replace the local deduplication key with the server one
          setMessages((prevMessages) =>
            prevMessages.map((message) =>
              message.deduplicationKey === localDeduplicationKey
                ? { ...message, deduplicationKey: serverDeduplicationKey }
                : message
            )
          );
        }
      });

      if (!isAgentJoined) {
        setTimeout(() => setThinking(true), 1000);
      }
    },
    [addMessage, isAgentJoined, sendMessage]
  );

  const handleAgentJoined = useCallback((agentDisplayName?: string) => {
    if (agentDisplayName == null) {
      return;
    }

    setAgents((prevAgents) => [...prevAgents, agentDisplayName]);
  }, []);

  const handleError = useCallback(
    (error: ErrorMessage) => {
      addMessage(error);
      window?.quack?.errorRepotingCallback?.(error);
    },
    [addMessage]
  );

  const messageHandler = useMessageHandler(
    addMessage,
    handleChatClosed,
    handleAgentJoined,
    handleError
  );

  const chatConnection = useConnectChatSession(connectionRef, messageHandler);

  useEffect(() => {
    if (chatSession == null) {
      return;
    }

    connectionRef?.current?.startConnection(chatSession);

    return () => {
      connectionRef?.current?.closeConnection();
    };
  }, [chatSession]);

  useRestoreChatMessages(messageHandler, tenant, chatSession?.chatSessionId);

  return (
    <>
      <QuackChatCompContainer
        open={open}
        settings={settings}
        onOpenChange={onOpenChange}
      >
        {!isLoading && chatSession != null && (
          <>
            {isDev && (
              <ConnectionStatus
                tenant={tenant}
                status={chatConnection.status}
                startNewChat={handleStartNewChat}
              />
            )}

            <QuackChatComp
              settings={settings}
              agents={agents}
              messages={messages}
              showCSAT={showCSAT}
              thinking={thinking}
              csatScore={csatMutation.data}
              chatEnded={chatEnded}
              isError={healthQuery.isError}
              sendMessage={handleUserSendMessage}
              uploadFile={onUploadFile}
              startNewChat={handleStartNewChat}
              onClose={onClose}
              onCSATScore={onCSATScore}
              onEscalate={onEscalate}
              onEscalationEmail={onEscalationEmail}
            />
          </>
        )}
      </QuackChatCompContainer>
    </>
  );
}
