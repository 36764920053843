import React, { PropsWithChildren, useState, useEffect } from "react";
import cx from "classnames";
import { useMediaQuery } from "react-responsive";
import * as Popover from "@radix-ui/react-popover";
import * as Dialog from "@radix-ui/react-dialog";
import { ChatSettingsV2 } from "../entities/ChatSettingsV2";
import { WidgetSVG } from "./loadSVG";

function useViewportHeight() {
  const getHeight = () =>
    window.visualViewport ? window.visualViewport.height : window.innerHeight;

  const [viewportHeight, setViewportHeight] = useState(getHeight());

  useEffect(() => {
    if (!window.visualViewport) return;

    const handleResize = () => {
      setViewportHeight(getHeight());
    };

    window.visualViewport?.addEventListener("resize", handleResize);

    return () =>
      window.visualViewport?.removeEventListener("resize", handleResize);
  }, []);

  return viewportHeight;
}

interface TriggerSVGProps {
  open: boolean;
  customSVG: string;
  closeSVG?: string;
  iconSize: number;
}

function TriggerSVG({ open, customSVG, closeSVG, iconSize }: TriggerSVGProps) {
  if (closeSVG == null) {
    return <WidgetSVG path={customSVG} iconSize={iconSize} />;
  }

  return (
    <>
      <WidgetSVG path={customSVG} iconSize={iconSize} hide={open} />
      <WidgetSVG path={closeSVG} iconSize={iconSize} hide={!open} />
    </>
  );
}

interface QuackChatCompContainerProps {
  open: boolean;
  settings: ChatSettingsV2;
  onOpenChange: (open: boolean) => void;
}

export function QuackChatCompContainer({
  children,
  open,
  onOpenChange,
  settings,
}: PropsWithChildren<QuackChatCompContainerProps>) {
  const {
    customSVG,
    closeSVG = customSVG,
    iconSize,
    position = "bottom-right",
  } = settings;

  const isSmallScreen = useMediaQuery({ query: "(max-width: 520px)" });
  const viewportHeight = useViewportHeight();
  const triggerClassName = cx("quackchat-root", "qc-Chat__Trigger", position);

  if (isSmallScreen) {
    return (
      <Dialog.Root open={open} onOpenChange={onOpenChange}>
        <Dialog.Trigger asChild>
          <button className={triggerClassName}>
            <TriggerSVG
              open={open}
              customSVG={customSVG}
              closeSVG={closeSVG}
              iconSize={iconSize}
            />
          </button>
        </Dialog.Trigger>

        <Dialog.Content
          className="qc-Chat__Widget_Dialog"
          style={{ height: `${viewportHeight}px` }}
        >
          {children}
        </Dialog.Content>
      </Dialog.Root>
    );
  }

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <button className={triggerClassName}>
          <TriggerSVG
            open={open}
            customSVG={customSVG}
            closeSVG={closeSVG}
            iconSize={iconSize}
          />
        </button>
      </Popover.Trigger>

      <Popover.Content
        align={position === "bottom-right" ? "end" : "start"}
        side={"top"}
        sideOffset={8}
        className="qc-Chat__Widget_Popover"
        onPointerDownOutside={(event) => {
          event.preventDefault();
        }}
      >
        {children}
      </Popover.Content>
    </Popover.Root>
  );
}
