import React from "react";
import { Text, Flex, IconButton } from "@radix-ui/themes";
import { useMediaQuery } from "react-responsive";
import { ChevronDownIcon, Cross1Icon } from "@radix-ui/react-icons";

interface HeaderProps {
  name: string;
  quackAgentName: string;
  agents?: string[];
  onClose?: () => void;
}

export function Header({
  name,
  quackAgentName,
  agents = [],
  onClose,
}: HeaderProps) {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 520px)" });

  return (
    <Flex py={"2"} px={"4"} gap={"6"} align={"center"}>
      <Flex flexGrow={"1"} direction={"column"}>
        <Text size={"3"} color={"gray"} weight={"medium"} highContrast>
          {name}
        </Text>

        <Text size={"2"} color={"gray"}>
          {[quackAgentName, ...agents].filter(Boolean).join(", ")}
        </Text>
      </Flex>

      <IconButton variant="ghost" color="gray" onClick={onClose}>
        {isSmallScreen ? (
          <Cross1Icon width="15" height="15" />
        ) : (
          <ChevronDownIcon width="15" height="15" />
        )}
      </IconButton>
    </Flex>
  );
}
