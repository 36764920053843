import { QuackSDK } from "./index";
import { S3_CONFIG } from "./constants";
import { ChatSettings, ISDKSettings, quackChatConfigSchema } from "./types";
import { getBackupValuesFromLS } from "./lib";
import { renderChat } from "./chat";
import { MixpanelEvents, mixTrackEvent } from "./assets/mixpanel";

const defaultSDKSettings: ISDKSettings = {
  tenantId: "",
  version: "v1",
  isActive: true,
  isTokenMasked: false,
  chat: false,
  openChatDefaultList: [""],
  whiteListUrls: [""],
  proactiveListUrls: [""],
  proactiveTime: 0,
  inject: false,
};

const defaultChatSettings: ChatSettings = {
  name: "Quack demo's AI Agent",
  quackAgentName: "AI Assistant",
  customSVG:
    '<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" shape-rendering="geometricPrecision" viewBox="0 0 24 24" class="jsx-3060654314" height="24" width="24" style="color: currentcolor;"><path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path></svg>',
  borderRadius: 8,
  iconSize: 48,
  iconPadding: 4,
  poweredByLine: false,
  escalationMessageToLiveAgent:
    "We understand that this inquiry requires the help of one of our Support Agents. Please hold while we connect you to one of our Agents.",
  escalationMessageToNotLiveAgent:
    "We understand that this inquiry requires the help of one of our Support Agents. At this time all of our agents are offline, so we will open a ticket to the team on your behalf. They will get back to you as soon as they can.",
  theme: {
    iconPrimary: "#1A1A1A",
    widgetPrimary: "#1A1A1A",
    background: "#FFFFFF",
    lightBorder: "#656665",
    textPlaceholder: "#8F8F8F",
    borderDefault: "#F1F1F1",
    bgCard: "#E6E6E6",
    bgInverted: "#1D1D1D",
    textPrimary: "#1A1A1A",
    textInverted: "#FFFFFF",
    borderLight: "#CBCBCB",
    bgPrimaryQuack: "#FFFFFF",
    bgChatWidget: "#ffffff00",
    bgInput: "#F0F0F0",
    agentBackground: "#6F5BE2",
    agentText: "#FAFBFC",
    userBackground: "#FFFFFF",
    userText: "#000000",
    bgSendButton: "#202020",
    textSendButton: "#fcfcfc",
  },
  disappearAfterClose: 10,
  fontSize: 14,
  waitTimeBeforeRefreshAfterEscalating: 300,
  openFullyOnOpenChat: false,
  csatDisabled: false,
  placeholder: "Ask anything...",
};

function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

function getComponent() {
  return import("./index")
    .then(({ QuackSDK }) => {
      const q = new QuackSDK();

      return q;
    })
    .catch((error) => "An error occurred while loading the component");
}

// unbind the triggering of end function from the start

class Quack {
  token: string;
  email: string;
  anonId: string;
  correlationId: string;
  QuackSdk: QuackSDK;
  settings: ISDKSettings;
  chatSettings: ChatSettings;
  chatStarted: boolean;
  trackFunc: (eventName: string, properties: any) => void;
  errorRepotingCallback: (error: unknown) => void;

  constructor() {
    this.correlationId = "";
    this.anonId = "";
    this.token = "";
    this.email = "";
    this.QuackSdk;
    this.settings = defaultSDKSettings;
    this.chatSettings = defaultChatSettings;
    this.chatStarted = false;
    this.trackFunc = () => {};
    this.track = this.track.bind(this);
    this.showChat = this.showChat.bind(this);
    this.hideChat = this.hideChat.bind(this);
    this.trackCallback = this.trackCallback.bind(this);
    this.disableProactive = this.disableProactive.bind(this);
    this.enableProactive = this.enableProactive.bind(this);
    this.identify = this.identify.bind(this);
    this.logout = this.logout.bind(this);
    this.addErrorHandler = this.addErrorHandler.bind(this);
  }

  logout() {
    setTimeout(() => {
      this.QuackSdk?.identify({ email: "", id: "" });
      this.email = "";
    }, 2000);
  }

  // initiate with token
  async initiate(token: string) {
    this.token = token;

    try {
      const el = document.getElementsByTagName("html")[0];
      if (el) {
        if (!el.style.backgroundColor) {
          el.style.background = "white";
        }
      }
    } catch (e) {}

    try {
      const result = await fetch(`${S3_CONFIG}configurations/${token}.json`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const response = await result.json();
      const { error, data } = quackChatConfigSchema.safeParse(response);

      if (error) {
        throw new Error("Quack: Invalid configuration");
      }

      const { chatSettings, ...settings } = data;

      if (settings) {
        this.settings = { ...this.settings, ...settings };
      }

      if (process.env.NODE_ENV === "development") {
        const { openChatDefaultList, whiteListUrls } = this.settings;
        this.settings.openChatDefaultList = [...openChatDefaultList, "file"];
        this.settings.whiteListUrls = [...whiteListUrls, "file"];
      }

      if (chatSettings) {
        this.chatSettings = {
          ...this.chatSettings,
          ...response.chatSettings,
          theme: {
            ...this.chatSettings.theme,
            ...response.chatSettings.theme,
          },
        };
      }

      this.anonId = getBackupValuesFromLS().anonId ?? uuidv4();
      this.correlationId = getBackupValuesFromLS().correlationId ?? uuidv4();

      if (this.settings.inject) {
        // instead should load here other script
        getComponent().then((q) => {
          this.QuackSdk = q as QuackSDK;
          // should initiate here with all the data prefetched.
          (q as any).initiate({
            correlationId: this.correlationId,
            organizationId: this.settings.tenantId,
            settings: this.settings,
            token: this.token,
            anonId: this.anonId,
          });
        });
      }

      if (this.settings.chat) {
        this.setupUrlChangeListener();
      }
    } catch (e) {
      console.error("Quack: seems like your quack token is wrong");
    }
  }

  async identify(props: any) {
    setTimeout(() => {
      const email = this.QuackSdk?.identify(props);
      if (email) {
        this.email = email;
      }
    }, 2000);
  }

  async clear() {
    this.QuackSdk?.clear();
  }

  setCustom(name: string, func: () => void) {
    // @ts-ignore
    this.QuackSdk[name] = func;
  }

  track(eventName: string, properties: any) {
    mixTrackEvent({
      tenant: this.settings.tenantId,
      sessionId: this.correlationId,
      properties,
      event: eventName as MixpanelEvents,
    });
    this.trackFunc(eventName, properties);
  }

  showChat() {
    const quackContainer = document.getElementById("quack-container");

    if (quackContainer != null && quackContainer.children.length > 0) {
      return;
    }

    if (quackContainer == null) {
      const el = document.createElement("div");
      el.id = "quack-container";
      document.body.appendChild(el);
    }

    renderChat({
      ...this.settings,
      chatSettings: this.chatSettings,
      token: this.token,
      sessionId: this.correlationId,
      email: this.email,
      track: (eventName, properties) => this.track(eventName, properties),
    });

    this.chatStarted = true;
  }

  disableProactive() {
    window.postMessage("quack-disable-proactive", "*");
  }

  enableProactive() {
    window.postMessage("quack-enable-proactive", "*");
  }

  hideChat() {
    const quackContainer = document.getElementById("quack-container");
    if (quackContainer) {
      quackContainer.remove();
    }
  }

  trackCallback(callback: (eventName: string, properties: any) => void) {
    this.trackFunc = callback;
  }

  setupUrlChangeListener() {
    const checkUrlAndShowChat = () => {
      const currentUrl = window.location.href;
      const openChatDefault = this.settings.openChatDefaultList.some((url) =>
        currentUrl.includes(url)
      );
      if (this.settings.chat && !this.chatStarted && openChatDefault) {
        setTimeout(() => this.showChat(), 300);
      }
    };

    checkUrlAndShowChat();

    (function (history) {
      const pushState = history.pushState;
      const replaceState = history.replaceState;

      history.pushState = function (state, title, url) {
        const result = pushState.apply(history, arguments);
        checkUrlAndShowChat();
        return result;
      };

      history.replaceState = function (state, title, url) {
        const result = replaceState.apply(history, arguments);
        checkUrlAndShowChat();
        return result;
      };

      window.addEventListener("popstate", checkUrlAndShowChat);
    })(window.history);
  }

  addErrorHandler(callback: (error: unknown) => void) {
    this.errorRepotingCallback = callback;
  }
}

// @ts-ignore
window["quack"] = new Quack();
const elem = document.getElementById("quack-license");
const token = elem?.getAttribute("quack");
if (token) {
  const el = document.createElement("div");
  el.id = "quack-container";
  document.body.appendChild(el);

  // @ts-ignore
  window["quack"].initiate(token);
}
