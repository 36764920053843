import React, { useMemo, useEffect, useRef } from "react";
import { Flex, ScrollArea } from "@radix-ui/themes";
import { Message } from "../entities/Message";
import { MessageComponent } from "./MessageComponent";
import { CSAT } from "./CSAT";

function BouncingLoader() {
  return (
    <div className="qc-Chat__BouncingLoader">
      <div />
      <div />
      <div />
    </div>
  );
}

interface MessagesProps {
  messages: Message[];
  customSVG: string;
  avatarSVG?: string;
  showCSAT?: boolean;
  thinking?: boolean;
  csatScore?: number;
  onCSATScore: (score: number) => void;
  onEscalate: () => Promise<void>;
  onEscalationEmail: (email: string) => Promise<void>;
}

export function Messages({
  messages,
  customSVG,
  avatarSVG,
  showCSAT,
  thinking,
  csatScore,
  onCSATScore,
  onEscalate,
  onEscalationEmail,
}: MessagesProps) {
  const uniqueMessages = useMemo<Message[]>(
    () =>
      Array.from(
        new Map(
          messages.map((message) => [message.deduplicationKey, message])
        ).values()
      ),
    [messages]
  );

  const visibleMessages = useMemo<Message[]>(() => {
    const hasAgentJoined = uniqueMessages.some(
      (message) => message.action === "agent_joined"
    );

    if (hasAgentJoined) {
      return uniqueMessages.filter(
        (message) => message.action !== "connecting_to_agent"
      );
    }

    return uniqueMessages;
  }, [uniqueMessages]);

  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [uniqueMessages, showCSAT, thinking]);

  return (
    <Flex flexGrow={"1"} direction={"column"} overflow={"hidden"}>
      <ScrollArea
        ref={scrollAreaRef}
        scrollbars={"vertical"}
        style={{ width: "100%" }}
      >
        <Flex p={"4"} direction={"column"} gap={"3"}>
          {visibleMessages
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map((message, index) => (
              <MessageComponent
                key={index}
                {...message}
                onEscalate={onEscalate}
                onEscalationEmail={onEscalationEmail}
                customSVG={avatarSVG ?? customSVG}
                isLastMessage={index === visibleMessages.length - 1}
              />
            ))}

          {thinking && <BouncingLoader />}
          {showCSAT && <CSAT csatScore={csatScore} onCSATScore={onCSATScore} />}
        </Flex>
      </ScrollArea>
    </Flex>
  );
}
