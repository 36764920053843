import { useEffect } from "react";
import { messageSchema } from "../core/entities/Message";
import { useGetChatMessages } from "../api/useGetChatMessages";

export function useRestoreChatMessages(
  messageHandler: (message: any) => void,
  tenant: string,
  chatSessionId?: string
) {
  const { data: allRestoredMessages } = useGetChatMessages(
    tenant,
    chatSessionId
  );

  useEffect(() => {
    if (allRestoredMessages != null) {
      allRestoredMessages.chatMessages.forEach((message) => {
        const { success, data } = messageSchema.safeParse(message);

        if (success) {
          messageHandler(JSON.stringify(data));
        }
      });
    }
  }, [messageHandler, allRestoredMessages]);
}
