import { useMutation } from "react-query";
import { useApi } from "./useApi";

interface CSATRequest {
  chatSessionId: string;
  file: File;
}

export function useUploadFile() {
  const { api } = useApi();

  return useMutation(async (data: CSATRequest) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("chatSessionId", data.chatSessionId);

    return api.post(`/sessions-api/quackchat/upload-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });
}
