import React from "react";
import { Flex, Separator, Avatar, Text, Link } from "@radix-ui/themes";
import { ChatSettingsV2 } from "../entities/ChatSettingsV2";
import { Header } from "./Header";
import { MessageAvatarSVG } from "./loadSVG";

interface QuackChatErrorProps {
  settings: ChatSettingsV2;
  onClose?: () => void;
}

export function QuackChatError({ settings, onClose }: QuackChatErrorProps) {
  return (
    <>
      <Header
        name={settings.name}
        quackAgentName={settings.quackAgentName}
        onClose={onClose}
      />

      <Separator orientation="horizontal" size="4" />

      <Flex
        flexGrow={"1"}
        p={"4"}
        direction={"column"}
        align={"center"}
        gap={"4"}
      >
        <Flex
          flexGrow={"1"}
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap={"4"}
        >
          <Flex className="qc-Chat__Error__Avatar">
            <MessageAvatarSVG path={settings.customSVG} />
          </Flex>

          <Flex direction={"column"} gap={"1"} align={"center"}>
            <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
              Looks like something's off right now.
            </Text>

            <Text size={"2"} color={"gray"}>
              I can' t fetch the info I need. Try again in a bit!
            </Text>
          </Flex>
        </Flex>

        {settings.fallbackSupportEmail && (
          <Flex direction={"column"} gap={"4"} align={"center"}>
            <Text
              size={"2"}
              color={"gray"}
              align={"center"}
              style={{ maxWidth: "324px" }}
            >
              {
                "If this keeps happening, you can reach out to our support team at "
              }

              <Link href={`mailto:${settings.fallbackSupportEmail}`}>
                {settings.fallbackSupportEmail}
              </Link>
            </Text>
          </Flex>
        )}
      </Flex>
    </>
  );
}
