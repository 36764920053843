import React, { useCallback } from "react";
import { Button, Em, Flex, Text } from "@radix-ui/themes";

function ScoreButton({
  level,
  label,
  onClick,
  hint,
  hintAlign = "center",
}: {
  level: "low" | "medium" | "high";
  label: string;
  onClick: () => void;
  hint?: string;
  hintAlign?: "left" | "center" | "right";
}) {
  const hasHint = hint != null;

  return (
    <Button
      size={"1"}
      variant={"outline"}
      color={"gray"}
      className={`qc-Chat__CSAT__ScoreButton ${
        hasHint ? "qc-Chat__CSAT__ScoreButton-has-hint" : ""
      } qc-Chat__CSAT__ScoreButton-level-${level}`}
      onClick={onClick}
    >
      {label}

      {hasHint && (
        <div
          className={`qc-Chat__CSAT__ScoreButton__Hint  qc-Chat__CSAT__ScoreButton__Hint-aling-${hintAlign}`}
        >
          {hint}
        </div>
      )}
    </Button>
  );
}

interface CSATProps {
  csatScore?: number;
  onCSATScore: (score: number) => void;
}

export function CSAT({ csatScore, onCSATScore }: CSATProps) {
  const handleScore = useCallback(
    (score: number) => {
      onCSATScore(score);
    },
    [onCSATScore]
  );

  if (csatScore != null) {
    return (
      <Text
        size={"2"}
        color={"gray"}
        style={{
          padding: "2px 8px",
        }}
      >
        <Em>Thank you for your feedback</Em>
      </Text>
    );
  }

  return (
    <Flex pt={"6"} px={"1"} gap={"10px"} direction={"column"}>
      <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
        How satisfied were you with our service today
      </Text>

      <Flex gap={"10px"}>
        <ScoreButton
          level={"low"}
          label={"😢"}
          onClick={() => handleScore(1)}
          hint={"Very dissatisfied"}
          hintAlign={"left"}
        />

        <ScoreButton
          level={"medium"}
          label={"🙁"}
          onClick={() => handleScore(2)}
        />

        <ScoreButton
          level={"medium"}
          label={"😐"}
          onClick={() => handleScore(3)}
          hint={"Neutral"}
        />

        <ScoreButton
          level={"medium"}
          label={"🙂"}
          onClick={() => handleScore(4)}
        />

        <ScoreButton
          level={"high"}
          label={"😊"}
          onClick={() => handleScore(5)}
          hint={"Very satisfied"}
          hintAlign={"right"}
        />
      </Flex>
    </Flex>
  );
}
