import { Message, useChat } from "ai/react";
import { useEffect, useRef, useState } from "react";

import { API_URL } from "../constants";
import { useEvents } from "../assets/mixpanel/useEvents";

export enum EToolCallName {
  ESCALATE = "talk_to_an_agent",
  SKIP = "skip_it",
  UPGRADE = "upgrade",
  FEEDBACK = "request_feedback",
}

export const PlanMapper = {
  business: {
    cta: "Talk to a Business expert",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/enterprise/contact-sales?utm_source=Chatbot"
        : "https://artlist.io/enterprise/contact-sales?utm_source=Chatbot",
  },
  "Music & SFX Social": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/personal"
        : "https://artlist.io/sign-up/personal",
  },
  "Music & SFX Pro": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/unlimited"
        : "https://artlist.io/sign-up/unlimited",
  },
  "Music Pro": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/unlimited"
        : "https://artlist.io/sign-up/unlimited",
  },
  "SFX Pro": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/unlimited"
        : "https://artlist.io/sign-up/unlimited",
  },
  "AI Voiceover": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/voice-over"
        : "https://artlist.io/sign-up/voice-over",
  },
  "Footage & Templates": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/footage-creator"
        : "https://artlist.io/sign-up/footage-creator",
  },
  Max: {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/max-pro"
        : "https://artlist.io/sign-up/max-pro",
  },
  "Max Teams": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/max-teams"
        : "https://artlist.io/sign-up/max-teams",
  },
  "Music & SFX Teams": {
    cta: "Click to continue",
    link: (isAuthenticated: boolean) =>
      isAuthenticated
        ? "https://artlist.io/checkout/teams"
        : "https://artlist.io/sign-up/teams",
  },
};

export const useAiChat = ({
  chatId,
  tenant,
  track,
  showProactive,
  firstMessage,
  fetchEmail,
  csatDisabled,
  escalationMessageToNotLiveAgent,
  setEscalation,
  handleEscalate,
  shouldPoll,
  handleFixMessage,
  abandonedChatQuestion,
}: {
  chatId: string;
  tenant: string;
  csatDisabled: boolean;
  track: (eventName: string, properties: any) => void;
  showProactive: boolean;
  firstMessage?: string;
  fetchEmail: () => string;
  escalationMessageToNotLiveAgent: string;
  setEscalation: React.Dispatch<React.SetStateAction<boolean>>;
  handleEscalate: (email: string) => void;
  shouldPoll?: boolean;
  handleFixMessage: (message: Message) => Promise<void>;
  abandonedChatQuestion?: { waitTime: number; question: string };
}) => {
  const noSandboxTenant = tenant.replace("-sandbox", "");
  const [timeoutTrigger, setTimeoutTrigger] = useState(0);

  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const [isDelayed, setIsDelayed] = useState(false);

  const [abandonedChatSent, setAbandonedChatSent] = useState<boolean>(
    !abandonedChatQuestion
  );

  const messagesRef = useRef<Message[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        messagesRef.current.length >= 2 &&
        !shouldPoll &&
        !csatDisabled &&
        abandonedChatSent
      ) {
        setMessages([
          ...messagesRef.current,
          {
            id: "timeout-message",
            content: "", // does this was helpful
            role: "assistant",
            toolInvocations: [
              {
                state: "result",
                toolName: "feedback",
                result: "request_feedback",
                toolCallId: "feedback_call_1",
                args: {},
              },
            ],
          },
        ]);
      }
    }, 45 * 1000);

    return () => clearTimeout(timer);
  }, [timeoutTrigger, shouldPoll, abandonedChatSent]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (
      !abandonedChatSent &&
      abandonedChatQuestion &&
      abandonedChatQuestion?.waitTime &&
      messagesRef.current.length >= 2 &&
      messagesRef.current[messagesRef.current.length - 1]?.role === "assistant"
    ) {
      timeout = setTimeout(() => {
        const message: Message = {
          id: "abandoned-chat-message",
          content: abandonedChatQuestion.question,
          role: "assistant",
        };
        setMessages([...messagesRef.current, message]);
        handleFixMessage(message);
        setAbandonedChatSent(true);
      }, (abandonedChatQuestion?.waitTime || 10000) * 1000);
    }

    return () => clearTimeout(timeout);
  }, [timeoutTrigger, abandonedChatQuestion]);

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    append,
    setMessages,
    reload,
  } = useChat({
    id: chatId,
    api: `${API_URL}sessions-api/chat-session/message`,
    headers: {
      "x-quack-token": "5f4dcc3b5aa765d61d8327deb882cf99",
    },
    body: {
      tenant: noSandboxTenant,
      chatSessionId: chatId,
    },

    onFinish: (message: Message & { toolInvocations?: any }) => {
      // handle escalation

      console.log({ message });
      console.log({ result: (message.toolInvocations?.[0] as any)?.result });
      events.trackMessageSent(false);
      if (
        [EToolCallName.SKIP, EToolCallName.ESCALATE].includes(
          (message.toolInvocations?.[0] as any)?.result
        )
      ) {
        console.log("Im here");
        // check if email
        const email = fetchEmail();

        if (email) {
          handleEscalate(email);
        } else {
          handleFixMessage({
            id: "emal-request",
            content:
              "We want to pass your message to our agent. Please, leave your email and we will contact you soon.",
            role: "assistant",
          });
          setMessages([
            ...messagesRef.current,
            {
              id: "emal-request",
              content:
                "We want to pass your message to our agent. Please, leave your email and we will contact you soon.",
              role: "assistant",
            },
          ]);
          setEscalation(true);
        }
      } else {
        console.log("Im there");
        setTimeoutTrigger(new Date().valueOf());
      }
    },
    initialMessages: firstMessage
      ? [{ id: "1", content: firstMessage, role: "assistant" }]
      : [],
  });

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const events = useEvents({
    track,
    messages,
    lastMessageId,
    chatId,
    loading: isLoading || isDelayed,
    tenant,
    showProactive,
  });

  return {
    messages: messages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading: shouldPoll ? false : isLoading || isDelayed,
    setMessages,
    isTyping: shouldPoll ? false : isLoading || isDelayed,
    events,
    append,
  };
};
