// Responsible on rendering the messages

import { Loading, Button as GeistButton, ButtonGroup } from "@geist-ui/core";
import { Message } from "ai/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowUpRight } from "@geist-ui/icons";

import { EMessagesContainerType, TypeContext } from "./typeContext";
import { fadeAndMoveUp } from "./utils";
import { EToolCallName, PlanMapper } from "../api/useChat";
import { API_URL } from "../constants";

export const MessageComponent = ({
  message,
  isLoading = false,
  icon = <></>,
  isLastMessage = false,
  fontSize,
  updateCSAT,
}: {
  message: Message & { agentDisplayName?: string };
  isLoading?: boolean;
  icon?: React.ReactNode;
  isLastMessage?: boolean;
  fontSize: number;
  updateCSAT: (value: number) => Promise<void>;
}) => {
  const { type, events } = useContext(TypeContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef<HTMLDivElement>(null);
  const feedbackBottomLineRef = useRef<HTMLDivElement>(null);
  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(0);

  const messageContainers = useMemo(() => {
    const formatContent = (content: string) => {
      if (!content.trim()) {
        return [];
      }

      const splitMessages = content.split(/\n\s*\n/); // double line breaks - new message

      return splitMessages.map((msg) => {
        const parts = msg.split(/(\*\*.*?\*\*|\[.*?\]\(.*?\))/g);
        return parts.map((part, index) => {
          if (part.startsWith("**") && part.endsWith("**")) {
            return <strong key={index}>{part.slice(2, -2)}</strong>;
          } else if (part.match(/\[.*?\]\(.*?\)/)) {
            const [text, url] = part.slice(1, -1).split("](");
            return (
              <StyledLink
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </StyledLink>
            );
          } else {
            return part
              .split("\n")
              .filter((line) => line.trim() !== "" && line.trim() !== ".")
              .map((line, lineIndex) => (
                <React.Fragment key={`${index}-${lineIndex}`}>
                  {line}
                  {lineIndex < part.split("\n").length - 1 && <br />}
                </React.Fragment>
              ));
          }
        });
      });
    };

    return formatContent(message.content);
  }, [JSON.stringify(message)]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (isLastMessage) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });

    if (messageRef.current) {
      observer.observe(messageRef.current);
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
      }
    };
  }, [isLastMessage, messageContainers.length]);

  const [cta, link] =
    (message.toolInvocations?.[0] as any)?.result?.action ===
    EToolCallName.UPGRADE
      ? [
          PlanMapper[
            (message.toolInvocations?.[0] as any)?.result
              ?.plan as keyof typeof PlanMapper
          ]?.cta,
          PlanMapper[
            (message.toolInvocations?.[0] as any)?.result
              ?.plan as keyof typeof PlanMapper
          ]?.link(!!window?.quack?.email),
        ]
      : [null, null];

  const isFeedback =
    (message.toolInvocations?.[0] as any)?.result === EToolCallName.FEEDBACK;
  const onCtaClick = () => {
    if (cta && link) {
      events.trackCTAButtonClicked(cta);
      setTimeout(() => {
        window.location.href = link;
      }, 200);
    }
  };

  useEffect(() => {
    if (feedbackBottomLineRef?.current) {
      feedbackBottomLineRef.current.scrollIntoView();
    }
  }, [isFeedback]);

  const hasAgentName = message?.agentDisplayName != null;

  return (
    <MessageWrapper className={hasAgentName ? "hasAgentName" : ""}>
      {hasAgentName && (
        <div className="agentHasJoined">
          {message.agentDisplayName} has joined the chat
        </div>
      )}

      {messageContainers.map((formattedMessage, index) => (
        <MessageContainer
          ref={messageRef}
          className={`message ${message.role === "user" ? "user" : "agent"} ${
            isLoading ? "loading" : ""
          } ${type === EMessagesContainerType.PROACTIVE ? "proactive" : ""}`}
          key={message.id + index}
          style={{ fontSize: `${fontSize}px` }}
        >
          {!isLoading ? (
            <div className="messageContent">
              {icon}
              {formattedMessage}
            </div>
          ) : (
            <Loading width={2.5} />
          )}
        </MessageContainer>
      ))}

      <div ref={messagesEndRef} />
      {isFeedback ? (
        <>
          {isFeedbackSubmitted ? (
            <Feedback>
              <div>Thanks for submission! </div>
            </Feedback>
          ) : (
            <Feedback>
              <div>Rate the conversation</div>
              <ButtonGroup>
                <GeistButton
                  ghost
                  loading={loadingIndex == 1}
                  type="secondary"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setLoadingIndex(1);
                    updateCSAT(1).then(() => {
                      setLoadingIndex(0);
                      setFeedbackSubmitted(true);
                    });
                  }}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      "https://quack-assets.s3.us-east-1.amazonaws.com/company/1.png"
                    }
                    alt=""
                  />
                </GeistButton>
                <GeistButton
                  ghost
                  loading={loadingIndex == 2}
                  type="secondary"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setLoadingIndex(2);
                    updateCSAT(2).then(() => {
                      setLoadingIndex(0);
                      setFeedbackSubmitted(true);
                    });
                  }}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      "https://quack-assets.s3.us-east-1.amazonaws.com/company/2.png"
                    }
                    alt=""
                  />
                </GeistButton>
                <GeistButton
                  ghost
                  loading={loadingIndex == 3}
                  type="secondary"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setLoadingIndex(3);
                    updateCSAT(3).then(() => {
                      setLoadingIndex(0);
                      setFeedbackSubmitted(true);
                    });
                  }}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      "https://quack-assets.s3.us-east-1.amazonaws.com/company/3.png"
                    }
                    alt=""
                  />
                </GeistButton>
                <GeistButton
                  ghost
                  loading={loadingIndex == 4}
                  type="secondary"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setLoadingIndex(4);
                    updateCSAT(4).then(() => {
                      setLoadingIndex(0);
                      setFeedbackSubmitted(true);
                    });
                  }}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      "https://quack-assets.s3.us-east-1.amazonaws.com/company/4.png"
                    }
                    alt=""
                  />
                </GeistButton>
                <GeistButton
                  ghost
                  loading={loadingIndex == 5}
                  type="secondary"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setLoadingIndex(5);
                    updateCSAT(5).then(() => {
                      setLoadingIndex(0);
                      setFeedbackSubmitted(true);
                    });
                  }}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={
                      "https://quack-assets.s3.us-east-1.amazonaws.com/company/5.png"
                    }
                    alt=""
                  />
                </GeistButton>
              </ButtonGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  fontSize: "10px",
                }}
                ref={feedbackBottomLineRef}
              >
                <span>Very dissatisfied</span>
                <span>Neutral</span>
                <span>Very satisfied</span>
              </div>
            </Feedback>
          )}
        </>
      ) : (
        <></>
      )}

      {cta ? (
        <Button onClick={onCtaClick}>
          {cta}

          <ArrowUpRight />
        </Button>
      ) : (
        <></>
      )}
    </MessageWrapper>
  );
};

const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  color: #616161;
  div:nth-of-type(2) {
    margin: 0 !important;
    width: fit-content !important;
    & > button {
      &:hover {
        background: #f2f2f2 !important;
      }
    }
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const MessageContainer = styled.div`
  display: flex;
  max-width: 85%;
  align-self: flex-end;
  padding: 10px;
  border-radius: 8px;
  background: ${(props) => props.theme.light.userBackground};
  color: ${(props) => props.theme.light.userText};
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  animation: ${fadeAndMoveUp} 0.2s ease-in-out;

  .messageContent {
    word-break: break-word;
  }

  &.agent {
    align-self: flex-start;
    background: ${(props) => props.theme.light.agentBackground};
    color: ${(props) => props.theme.light.agentText};
  }

  &.loading {
    justify-content: flex-start;
    background: unset;

    > * {
      width: 45px;
    }
  }

  &.proactive {
    max-width: 100%;
    width: fit-content;
    .messageContent {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
`;

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: inherit;
`;

const Button = styled.button`
  all: unset;
  font-family: Inter;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.light.borderLight};
  background: ${(props) => props.theme.light.bgCard};
  color: ${(props) => props.theme.light.textPrimary};
  text-align: center;
  font-size: 13.792px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.689px;
  cursor: pointer;

  svg {
    width: 13.792px;
    height: 13.792px;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.light.borderDefault};
  }
`;
