import { useCallback } from "react";
import { ErrorMessage, Message, messageSchema } from "./core/entities/Message";

export function useMessageHandler(
  addMessage: (message: Message) => void,
  onChatClosed: (showCSAT: boolean) => void,
  onAgentJoined: (agentDisplayName?: string) => void,
  onError: (message: ErrorMessage) => void
) {
  return useCallback(
    (message: any) => {
      try {
        const { success, data } = messageSchema.safeParse(JSON.parse(message));

        if (success) {
          switch (data.action) {
            case "close_chat":
              onChatClosed(true);
              break;
            case "agent_joined":
              onAgentJoined(data.data.agentDisplayName);
              addMessage(data);
              break;
            case "error":
              onError(data);
              break;
            default:
              addMessage(data);
              break;
          }
        }
      } catch (error) {}
    },
    [addMessage, onChatClosed, onAgentJoined]
  );
}
