import React, { useCallback, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { S3_CONFIG } from "../constants";
import { MixpanelEvents } from "../assets/mixpanel";
import { ChatSettings } from "../types";
import { QuackChatWidgetTheme } from "./core/parts/QuackChatWidgetTheme";
import { ApiProvider } from "./api/useApi";
import { SentryScopeProvider } from "./hooks/useSentryScope";
import { QuackChat } from "./QuackChat";
import "./core/styles/index.scss";

const queryClient = new QueryClient();

interface QuackChatWidgetProps {
  token: string;
  tenant: string;
  settings: ChatSettings;
  track: (eventName: string, properties: any) => void;
}

export function QuackChatWidget({
  token,
  tenant,
  track,
  ...props
}: QuackChatWidgetProps) {
  const [open, setOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const settings = useMemo(() => {
    const { customSVG, closeSVG, avatarSVG } = props.settings;

    return {
      ...props.settings,
      customSVG: `${S3_CONFIG}${customSVG}`,
      ...(closeSVG != null ? { closeSVG: `${S3_CONFIG}${closeSVG}` } : {}),
      ...(avatarSVG != null ? { avatarSVG: `${S3_CONFIG}${avatarSVG}` } : {}),
    };
  }, [props.settings]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);

      track(MixpanelEvents.ChatButtonClicked, {
        tenant,
        opened: open,
        event: MixpanelEvents.ChatButtonClicked,
      });

      if (open) {
        setInitialized((prevState) => prevState || true);
      }
    },
    [tenant, open, track]
  );

  return (
    <SentryScopeProvider>
      <QueryClientProvider client={queryClient}>
        <QuackChatWidgetTheme themeVars={settings.theme}>
          <ApiProvider>
            <QuackChat
              token={token}
              tenant={tenant}
              settings={settings}
              initialized={initialized}
              open={open}
              onOpenChange={handleOpenChange}
            />
          </ApiProvider>
        </QuackChatWidgetTheme>
      </QueryClientProvider>
    </SentryScopeProvider>
  );
}
