import React from "react";

export function QuackLogo() {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2767_162982)">
        <path
          d="M0.504158 2.7627C0.503914 2.76426 0.503914 2.76582 0.503914 2.76738L0.5 2.7627H0.504158Z"
          fill="black"
        />
        <path
          d="M2.14746 4.64455C1.23702 4.64117 0.5 3.85528 0.5 2.88626C0.5 2.8462 0.501223 2.80666 0.503914 2.76738C0.503914 2.76582 0.503914 2.76426 0.504158 2.7627H1.76685C1.75633 2.86909 1.75095 2.97731 1.75095 3.08657C1.75095 3.50696 1.82996 3.90757 1.9733 4.27281C2.02345 4.40158 2.08166 4.52566 2.14746 4.64455Z"
          fill="url(#paint0_linear_2767_162982)"
        />
        <path
          d="M4.6533 0C3.74139 0 2.92756 0.447441 2.39577 1.14696C2.05234 1.59804 1.82656 2.15422 1.76688 2.76269C1.75636 2.86908 1.75098 2.9773 1.75098 3.08656C1.75098 3.50695 1.82999 3.90756 1.97333 4.2728C2.02347 4.40157 2.08169 4.52566 2.14749 4.64454C2.26368 4.85551 2.40287 5.05062 2.56138 5.22595C3.0895 5.80971 3.83141 6.17312 4.6533 6.17312C5.17824 6.17312 5.67065 6.02484 6.09554 5.76548C6.96783 5.23324 7.55538 4.23274 7.55538 3.08656C7.55538 1.38186 6.256 0 4.6533 0ZM3.42364 1.76115C3.48161 1.74216 3.54325 1.73201 3.6071 1.73201C3.95347 1.73201 4.23404 2.03039 4.23404 2.39849C4.23404 2.69505 4.05229 2.94609 3.80083 3.03271C3.73992 3.05378 3.67461 3.06523 3.6071 3.06523C3.26097 3.06523 2.9804 2.76685 2.9804 2.39849C2.9804 2.09829 3.16704 1.84439 3.42364 1.76115Z"
          fill="#FBBF2A"
        />
        <path
          d="M9.98254 9.20199C8.96397 11.3583 7.0533 11.9998 5.48436 11.9998C3.91542 11.9998 1.06152 10.5297 1.06152 8.07689C1.06152 5.81289 2.538 5.2346 2.561 5.22575C3.08912 5.80951 3.83102 6.17292 4.65292 6.17292C5.17786 6.17292 5.67027 6.02464 6.09516 5.76528C6.66584 6.15055 7.49654 6.54102 8.32896 6.32953C9.81791 5.95154 10.1809 4.89355 10.1809 4.89355C10.6772 6.54544 10.5809 7.93563 9.98254 9.20199Z"
          fill="#FED942"
        />
        <path
          d="M10.2346 6.22168C10.0389 8.75752 8.06392 10.7497 5.65645 10.7497C4.01143 10.7497 2.56846 9.81942 1.75684 8.42142C2.6597 9.52494 3.98256 10.2213 5.4566 10.2213C7.76085 10.2213 9.69549 8.52028 10.2346 6.22168Z"
          fill="#FF9101"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2767_162982"
          x1="1.97991"
          y1="3.694"
          x2="0.453782"
          y2="2.8401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF5146" />
          <stop offset="1" stop-color="#FE802F" />
        </linearGradient>
        <clipPath id="clip0_2767_162982">
          <rect
            width="10"
            height="15"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
